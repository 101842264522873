import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import comment_post_small from 'images/comment_post_small.png';
import like_post_small from 'images/like_post_small.png';
import productTagImg from 'images/productTag.png';
import camIcon from 'images/baseline_videocam_white_18dp.png';

const emerge = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const Layout = styled.div`
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 32px;
	padding-right: 32px;
	position: relative;
	z-index: 0;
	cursor: pointer;
	height: 100%;
	border-radius: 16px;
	// overflow: hidden;
	animation: ${emerge} 0.5s;
	animation-fill-mode: forwards;
	// :after {
	// 	position: absolute;
	// 	top: 0px;
	// 	left: 0px;
	// 	width: 100%;
	// 	height: 100%;
	// 	border-radius: 10px;
	// 	background: rgba(0, 0, 0, 0);
	// 	content: '';
	// 	z-index: -1;
	// }
`;
const Video = styled.video`
	position: absolute;
	z-index: -1;
	cursor: pointer;
	height: 100%;
	border-radius: 16px;
	object-fit: cover;
	left: 0;
	right: 0;
	width: 100%;

	top: 0;
`;
const Wrapper = styled.div`
	position: relative;
	z-index: 0;
	cursor: pointer;
	height: 100%;
	padding-top: 132%;
	border-radius: 16px;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
	// overflow: hidden;
	background-size: cover;
	background-position: center;

	// &:active {
	// 	background-color: transparent !important;
	// }
`;
const CamIcon = styled.div`
	position: absolute;
	right: 12px;
	top: 12px;
`;
const Title = styled.div`
	width: 100%;
	text-align: left;
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 500;

	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	margin-bottom: 8px;
`;
const SubInfo = styled.div`
	width: 100%;
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 12px;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Item = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
const MiniIcon = styled.div`
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-right: 8px;
	vertical-align: super;
	background-size: cover;
	background-position: center;
`;
const Text = styled.div`
	display: inline-block;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	color: #f8f8f8;
	font-family: 'Noto Sans CJK KR';
	font-size: 12px;
	font-weight: 500;
	vertical-align: super;
`;
const InfoWrapper = styled.div`
	display: flex;
	height: 100%;
	align-items: flex-end;
	z-index: 500000;
`;
const Info = styled.div`
	padding: 20px;
	width: 100%;
`;
const GradientFilter = styled.div`
	position: absolute;
	left: 0px;
	width: 100%;
	height: 25%;
	z-index: -1;
	${props =>
		props.top &&
		css`
			top: 0px;
			border-radius: 10px 10px 0px 0px;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}

	${props =>
		props.bottom &&
		css`
			bottom: 0px;
			border-radius: 0px 0px 10px 10px;
			background-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}
`;
class Card extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.props.data,
		};
	}
	componentDidMount() {}
	render() {
		const data = JSON.parse(this.props.data);
		return (
			<Layout>
				<Wrapper
					style={{
						backgroundImage: 'url(' + data.thumbnail_image + ')',
					}}
					onClick={this.props.onClick}
				>
					{data.boomerang && (
						<Video
							className="VIDEOS"
							src={data.boomerang}
							autoPlay
							muted
							loop
							playsInline
							onPause={e => {
								// alert(e);
								// e.target.play();
								// window.location.reload();
							}}
						/>
					)}
					{/* <Video src={data.boomerang} autoPlay muted loop playsInline /> */}
					{/* <GradientFilter top /> */}

					{/* <GradientFilter bottom /> */}
					<InfoWrapper>
						<Info>
							<Title> {data.title} </Title>
							<SubInfo>
								<MiniIcon
									style={{ backgroundImage: `url('${like_post_small}')` }}
								/>{' '}
								<Text style={{ marginRight: 12 }}>{data.like.count}</Text>
								<MiniIcon
									style={{
										backgroundImage: `url('${comment_post_small}')`,
									}}
								/>{' '}
								<Text style={{ marginLeft: -3 }}>
									{data.comment && data.comment.count}
								</Text>
								<Item>
									<MiniIcon
										style={{
											backgroundImage: `url('${productTagImg}')`,
										}}
									/>{' '}
									<Text style={{ marginLeft: -3 }}>
										{data.item.length}개의 아이템
									</Text>
								</Item>
							</SubInfo>
						</Info>
					</InfoWrapper>
				</Wrapper>
			</Layout>
		);
	}
}
export default Card;
