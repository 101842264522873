import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import { Wrap, IndexWrap } from 'components/style/custom-styled-components';

import Footer from 'components/footer';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import ProfileTabs from 'components/profileTabs';
import TopNavBar from 'components/nav/navBar';
import InstaIcon from 'components/myIcons/instaIcon';
import YoutubeIcon from 'components/myIcons/youtubeIcon';
import ItemLinkRequiredModal from 'components/popModal/itemLinkRequiredModal';
import ProductCategoryModal from 'components/popModal/productCategoryModal';
import * as userAPI from 'axios/userAPI';
import * as eventAPI from 'axios/eventAPI';
import history from 'utils/history';
import UserProfilePage from './userProfilePage';
import default_profile from 'images/default_profile.png';

const ProfileDesc = styled.p`
	color: #999999;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 4px;
`;
const ProfileWrap = styled.div`
	padding: 16px;
	margin-top: 44px;
	display: flex;
	flex-direction: row;
	align-items: center;
	p {
		margin: 0px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	p.profile-name {
		color: #333333;
		font-family: 'Noto Sans CJK KR';
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 4px;
		flex: 1;
	}
	div.profile-sns {
		display: flex;
		flex-direction: row;
		align-items: center;
		.profile-sns-connect {
			color: #999999;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 500;
		}
	}
	span.profile-edit-btn {
		display: inline-block;
		text-align: center;
		width: 94px;
		height: 28px;
		border-radius: 14px;
		background-color: rgba(130, 143, 247, 0.2);

		color: #828ff7;
		font-family: 'Noto Sans CJK KR';
		font-size: 13px;
		font-weight: 700;
		line-height: 28px;
		cursor: pointer;
		hover: {
			opacity: 0.8;
		}
	}
`;
const ProfileImage = styled.div`
	width: 64px;
	height: 64px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	flex: 0 0 auto;
	margin-right: 16px;
	border-radius: 50%;
	display: flex;
	div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		${props =>
			props.isDefaultProfileImg &&
			css`
				margin: auto;
				width: 32px;
				height: 32px;
				border-radius: 0;
			`}
	}
`;
const InfoCard = styled.div`
	width: 100%;
	height: 92px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ffffff;

	p {
		width: 100%;
		height: 47px;
		padding: 10px 16px;
		border-bottom: solid 1px #eeeeee;
		margin: 0;
		span.profile-grade {
			color: #bebebe;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 500;
			line-height: 30px;
		}
		span.profile-grade-data {
			margin-left: 16px;
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 700;
			line-height: 30px;
			text-transform: uppercase;
		}
		span.profile-grade-icon {
			float: right;
		}
	}
`;
const InfoCardInnerWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	padding: 16px 0px;
	div {
		text-align: center;
		width: 25%;
		span {
			display: block;
		}
		span.profile-info-title {
			color: #bebebe;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 500;
		}
		span.profile-info-data {
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			font-size: 24px;
			font-weight: 700;
		}
	}
`;
const IconWrap = styled.i`
	cursor: pointer;
	font-family: 'Noto Sans CJK KR';
	text-decoration: none;
	font-style: normal;
	color: #999999;
	font-weight: bold;
	font-size: 12px;
	:hover {
		opacity: 0.8;
	}
	.icon {
		margin-right: 4px;
	}
	svg {
		margin-right: 6px !important;
		margin-bottom: 0px;
	}
`;

@inject('cacheStore')
@inject('userStore')
@observer
class MyPage extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			userId: null,
			initData: this.props.cacheStore.myProfileData,
			eventBanner: this.props.cacheStore.myEventBanner,
			accessToken: this.props.userStore.auth_token,
			changed: false,
			productCategoryModalOpen: false,
		};
	}
	componentWillMount() {
		if (!this.props.userStore.auth_token) {
			history.push('/login');
		}
	}
	async componentDidMount() {
		this._isMounted = true;
		const headers = {
			Authorization: `Bearer ${this.state.accessToken}`,
		};
		const req = {
			mode: 'main',
			headers: headers,
		};
		const initData = await Promise.all([
			userAPI.getMyUserProfile(req).then(res => {
				return res.data;
			}),
		]);

		if (initData.length && initData[0].id)
			this.setState({
				userId: initData[0].id,
			});
		console.log(initData);
		const eventBanner = await Promise.all([
			eventAPI.getEventBanner(req).then(res => {
				return res.data;
			}),
		]);
		if (this._isMounted) {
			this.setState(
				{
					initData: initData[0],
					eventBanner: eventBanner,
					changed: true,
				},
				() => {
					this.props.cacheStore.setMyProfileData(this.state.initData);
				},
			);
		}
	}

	toSns = url => {
		if (url) window.open(url, '_blank');
	};
	componentWillUnmount() {
		this._isMounted = false;
		this.props.cacheStore.setMyProfileData(this.state.initData);
		this.props.cacheStore.setMyEventBanner(this.state.eventBanner);
		this.props.cacheStore.setUserProfileData(this.state.initData);
	}
	handleClickAdd = () => {
		this.setState({
			productCategoryModalOpen: true,
		});
	};
	render() {
		const data = this.state.initData;
		const instagram = data && data.instagram;
		const instaId =
			instagram && instagram.slice('http://instagram.com/'.length);
		const youtube = data && data.youtube;
		return (
			<Wrap
				style={{
					paddingBottom: 0,
					height: '-webkit-fill-available',
					backgroundColor: 'rgb(248,248,248)',
					overflowY: 'hidden',
				}}
			>
				<UserProfilePage
					// onpopstate는 search에서만 사용
					postsSwiperClass={'myPage-posts-swiper'}
					id={this.state.userId}
					mine
					onClickItem={({ index }) => {
						// 클릭시에만 넘어갈 수 있게 풀어준다.
						// this.swiper.slideNext();
					}}
				/>
				<Footer
					location={this.props.location.pathname}
					onClickAdd={this.handleClickAdd}
				/>
				<ProductCategoryModal
					open={this.state.productCategoryModalOpen}
					handleClose={() =>
						this.setState({
							productCategoryModalOpen: false,
						})
					}
					onClick={selected => {
						if (this.props.userStore.getTokenOrToggleLogin())
							history.push(`/new-post?selected=${selected}`);
					}}
					bottom={'0px'}
				/>
			</Wrap>
		);
	}
}
export default MyPage;
