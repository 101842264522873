import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import styled, { css } from 'styled-components';
import { Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import Picker from 'rmc-picker';
import Popup from 'rmc-picker/lib/Popup';
import moment from 'moment';

import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';

import SignupFilterBtn from 'components/signup/signupFilterBtn';
import StyleBtn from 'components/signup/styleBtn';

import InstaIcon from 'components/myIcons/instaIcon';
import YoutubeIcon from 'components/myIcons/youtubeIcon';

import * as userAPI from 'axios/userAPI';
import history from 'utils/history';
import getDaysInMonth from 'utils/myDateRange';
import * as clientSwitcher from 'utils/switchForClient';
import * as serverSwitcher from 'utils/switchForServer';

import back_ico from 'images/back_ico_b.svg';
import default_profile from 'images/default_profile.svg';
import imgAdd from 'images/imgAdd.svg';

const ProfileImgUp = styled.div`
	margin: auto;
	margin-bottom: 24px;
	position: relative;
	width: 104px;
	height: 104px;
	background-color: #ebebeb;
	border-radius: 50%;
	background-size: cover;
	background-position: center;
	text-align: center;
	display: flex;
	align-items: center;
	input {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		opacity: 0;
		overflow: hidden;
	}
	label {
		cursor: pointer;
		height: 28px;
		width: 75px;
	}
	div.profile-image {
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		${props =>
			props.isDefaultProfileImg &&
			css`
				margin: auto;
				width: 42px;
				height: 42px;
				border-radius: 0;
			`}
	}
`;
const ImgBtn = styled.div`
	position: absolute !important;
	bottom: 0px;
	right: 0px;
	width: 32px;
	height: 32px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	border-radius: 50%;
	:hover {
		opacity: 0.7;
	}
`;
const Dot = styled.div`
	position: absolute !important;
	top: 13.5px;
	left: 13px;
	width: 6px;
	height: 6px;
	background-color: #999999;
	border-radius: 50%;
`;
const NavBar = styled.div`
	display: flex;
	flex-direction: row;
	position: fixed;
	line-height: 56px;
	height: 56px;
	width: 100%;
	max-width: 1024px;
	z-index: 3;
	top: 0;
	background: rgb(248, 248, 248);
`;
const ContentWrap = styled.div`
	padding: 16px;
	padding-bottom: 76px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 700;
	p {
		margin-bottom: 10px;
	}
	p.my-filter {
		span {
			float: right;
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			font-size: 15px;
			font-weight: 700;
			line-height: 28px;
		}
	}
	p.sns-title {
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 4px;
	}
	span.my-filter-update-btn {
		cursor: pointer;
		:hover {
			opacity: 0.8;
		}
	}
	div {
		position: relative;
	}
`;
const UpdateInput = styled.input`
	width: 100%;
	height: 44px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ffffff;
	border: none;
	margin-bottom: 40px;
	padding: 0px 16px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	::placeholder,
	::-webkit-input-placeholder {
		color: #bebebe;
	}
`;
const SettingBox = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 16px;
	margin-bottom: 24px;
	min-height: 104px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ffffff;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 15px;
	font-weight: 500;
	span {
		flex: 1 1 auto;
		display: inline-block;
		width: 100%;
	}
	div {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 24px;
	}
	div:last-child {
		margin-bottom: 0px;
	}
	div.filter-btn {
		margin-bottom: 24px;
		display: inline-block;
	}
`;
const IconWrapper = styled.i`
	z-index: 0;
	position: absolute;
	right: 16px;
	top: 12px;
	span {
		margin-left: 11px;
		vertical-align: text-bottom;
		font-style: normal;
		font-weight: 500;
		color: rgb(172, 172, 172);
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
	}
`;
const DropDownWrap = styled.div`
	display: flex;
	margin-bottom: 40px;
	span {
		width: 100%;
		flex: 1 1 auto;
		margin-left: 8px;
	}
	span:first-child {
		margin-left: 0px;
	}
`;
const MyDropDown = styled.button`
	position: relative;
	width: 100%;
	height: 44px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background-color: #ffffff;
	border: none;

	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	padding: 11px 16px;
	text-align: left;
`;
const SubmitWrap = styled.div`
	width: 100%;
	max-width: 1024px;
	margin: auto;
	padding: 16px;
	position: fixed !important;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background: rgb(248, 248, 248);
`;
const SubmitBtn = styled.button`
	border: none;
	width: 100%;
	height: 44px;
	box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
	border-radius: 68px;
	background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
const ErrorMsg = styled.p`
	height: 26px;
	border: 1px solid rgba(0, 0, 0, 0);
	color: #f85e3a;
	font-family: 'Noto Sans CJK KR';
	font-size: 12px;
	font-weight: 500;
	line-height: 26px;
`;
const BirthPlaceholder = styled.div`
	position: absolute;
	color: #bebebe;
	padding-top: 3px;
	top: 0px;
`;
var currentTime = moment();

@inject('userStore')
@observer
class MyProfileUpdate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			accessToken: this.props.userStore.auth_token,
			years: [],
			yearVal: currentTime.year(),
			monthVal: '',
			dayVal: '',
			profile: null,
			profileImg: null,
			profileImgURL: null,
			nickname: '',
			introduction: '',
			insta: '',
			youtube: '',
			stature: null,
			size: null,
			bottomSize: null,
			skin: null,
			styleTags: [],
			nicknameEditing: false,
			introductionEditing: false,
			gender: 'f',
		};
	}
	async componentWillMount() {
		if (!this.props.userStore.auth_token) {
			history.push('/login');
		} else {
			const headers = {
				Authorization: `Bearer ${this.props.userStore.auth_token}`,
			};
			const req = {
				headers: headers,
			};
			const initData = await Promise.all([
				userAPI.getMyUserProfile(req).then(res => {
					return res.data;
				}),
			]);
			const styleTags = await Promise.all([
				userAPI.getStyleList().then(res => {
					return res.data;
				}),
			]);
			let birthday = initData[0].birthday;
			birthday ? (birthday = birthday.split('-')) : (birthday = null);
			this.setState({
				profile: initData[0],
				profileImgURL: initData[0].image,
				nickname: initData[0].nickname,
				introduction: initData[0].introduction,
				youtube: initData[0].youtube ? initData[0].youtube : '',
				insta: initData[0].instagram ? initData[0].instagram : '',
				yearVal: birthday ? parseInt(birthday[0]) : this.state.yearVal,
				monthVal: birthday ? parseInt(birthday[1]) : this.state.monthVal,
				dayVal: birthday ? parseInt(birthday[2]) : this.state.dayVal,
				stature: clientSwitcher.switchProfileHeight(initData[0].height),
				size: clientSwitcher.switchProfileSize(initData[0].size),
				bottomSize: clientSwitcher.switchProfileSize(initData[0].bottomSize),
				skin: clientSwitcher.switchProfileSkin(initData[0].skintype),
				styleTags: styleTags[0],
				myTags: [],
				nicknameValid: true,
				gender: initData[0].gender || 'f',
			});
		}
	}
	componentDidMount() {
		let startYear = 1940;
		let years = [startYear];
		while (startYear <= currentTime.year()) {
			years.push(startYear++);
		}
		this.setState({
			years: years,
		});
	}
	handleImage = e => {
		if (e.target.files.length > 0) {
			this.setState({
				profileImg: e.target.files[0],
				profileImgURL: window.URL.createObjectURL(e.target.files[0]),
			});
		} else {
			this.setState({
				profileImg: null,
				profileImgURL: null,
			});
		}
	};

	handleInput = e => {
		if (e.target.value) {
			if (e.target.name == 'insta') {
				const val = e.target.value.split('http://instagram.com/');
				let valueBolt = false;
				if (val[0].length > 3 && val[0].length < 21) {
					if ('http://instagram.com/'.indexOf(e.target.value) !== -1) {
						valueBolt = false;
					} else {
						valueBolt = true;
					}
				} else valueBolt = true;
				if (valueBolt == true) {
					this.setState({
						[e.target.name + 'Editing']: true,
						[e.target.name]: 'http://instagram.com/' + val[val.length - 1],
					});
				} else {
					this.setState({
						[e.target.name + 'Editing']: false,
						[e.target.name]: '',
					});
				}
			} else {
				this.setState({
					[e.target.name + 'Editing']: true,
					[e.target.name]: e.target.value,
				});
			}
		} else {
			this.setState({
				[e.target.name + 'Editing']: false,
				[e.target.name]: '',
			});
		}
	};

	resetInput = name => {
		this.setState({
			[name + 'Editing']: false,
			[name]: '',
		});
	};

	_Y_onScrollChange = value => {
		this.setState({
			yearVal: value,
		});
	};
	_M_onScrollChange = value => {
		this.setState({
			monthVal: value,
		});
	};
	_D_onScrollChange = value => {
		this.setState({
			dayVal: value,
		});
	};

	_GENDER_onScrollChange = value => {
		this.setState({
			gender: value,
		});
	};
	toFilterUpdate = () => {
		history.push('/signup/style', { prevRoot: '/my-page/update' });
	};
	formSubmit = async () => {
		if (this.state.nickname == '') {
			alert('닉네임을 입력해주세요');
		} else {
			let birthday = undefined;
			if (this.state.dayVal) {
				birthday = `${String(this.state.yearVal)}-${String(
					this.state.monthVal,
				)}-${String(this.state.dayVal)}`;
			}
			const formData = new FormData();
			this.state.profileImg && formData.append('image', this.state.profileImg);
			formData.append('nickname', this.state.nickname);
			formData.append('introduction', this.state.introduction);
			formData.append('instagram', this.state.insta);
			formData.append('youtube', this.state.youtube);
			formData.append(
				'height',
				serverSwitcher.switchProfileHeight(String(this.state.stature)),
			);
			formData.append(
				'size',
				serverSwitcher.switchProfileSize(this.state.size),
			);
			formData.append(
				'bottomSize',
				serverSwitcher.switchProfileSize(this.state.bottomSize),
			);
			formData.append(
				'skintype',
				serverSwitcher.switchProfileSkin(this.state.skin),
			);
			formData.append('gender', this.state.gender);
			birthday && formData.append('birthday', birthday);
			const req = {
				headers: {
					Authorization: `Bearer ${this.state.accessToken}`,
					'Content-Type': 'multipart/form-data',
				},
				data: formData,
			};
			const result = await Promise.all([
				userAPI
					.patchUserProfile(req)
					.then(res => {
						const req = {
							headers: {
								Authorization: `Bearer ${this.state.accessToken}`,
							},
							data: {
								style: this.state.myTags,
							},
						};
						userAPI
							.patchUserProfile(req)
							.then(res => {
								return res.data;
							})
							.catch(e => {
								return false;
							});
						return res.data;
					})
					.catch(e => {
						if (e.response.data.nickname) {
							this.setState(
								{
									nicknameValid: false,
								},
								() => {
									scroll.scrollToTop({
										duration: 200,
										delay: 0,
										smooth: true,
									});
								},
							);
						}
						return false;
					}),
			]);
			if (result[0]) history.push('/my-page');
		}
	};
	handleStatureBtn = val => {
		if (this.state.stature != val) {
			this.setState({
				stature: val,
			});
		} else {
			this.setState({
				stature: '전체',
			});
		}
	};
	handleSizeBtn = val => {
		if (this.state.size != val) {
			this.setState({
				size: val,
			});
		} else {
			this.setState({
				size: '전체',
			});
		}
	};
	handleBottomSizeBtn = val => {
		if (this.state.bottomSize != val) {
			this.setState({
				bottomSize: val,
			});
		} else {
			this.setState({
				bottomSize: '전체',
			});
		}
	};
	handleSkinBtn = val => {
		if (this.state.skin != val) {
			this.setState({
				skin: val,
			});
		} else {
			this.setState({
				skin: '전체',
			});
		}
	};
	handleStyleBtn = tag => {
		let array = this.state.myTags;
		var index = array.indexOf(tag);
		if (index !== -1) array.splice(index, 1);
		else array.push(tag);
		this.setState({
			myTags: array,
		});
	};
	render() {
		const genderContent = (
			<div
				style={{
					maxWidth: 480,
					height: 160,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Picker
					indicatorClassName="my-picker-indicator"
					selectedValue={this.state.gender}
					onScrollChange={this._GENDER_onScrollChange}
				>
					<Picker.Item className="my-picker-view-item" value={'f'}>
						여성
					</Picker.Item>
					<Picker.Item className="my-picker-view-item" value={'m'}>
						남성
					</Picker.Item>
				</Picker>
			</div>
		);
		const yearPopupContent = (
			<div
				style={{
					maxWidth: 480,
					height: 160,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Picker
					indicatorClassName="my-picker-indicator"
					selectedValue={this.state.yearVal}
					onScrollChange={this._Y_onScrollChange}
				>
					{this.state.years.map((year, index) => {
						return (
							<Picker.Item
								className="my-picker-view-item"
								value={year}
								key={index}
							>
								{year}
							</Picker.Item>
						);
					})}
				</Picker>
			</div>
		);
		const monthPopupContent = (
			<div
				style={{
					maxWidth: 480,
					height: 160,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Picker
					indicatorClassName="my-picker-indicator"
					selectedValue={this.state.monthVal}
					onScrollChange={this._M_onScrollChange}
				>
					{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month, index) => {
						return (
							<Picker.Item
								className="my-picker-view-item"
								value={month}
								key={index}
							>
								{month}
							</Picker.Item>
						);
					})}
				</Picker>
			</div>
		);
		const dayPopupContent = (
			<div
				style={{
					maxWidth: 480,
					height: 160,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Picker
					indicatorClassName="my-picker-indicator"
					selectedValue={this.state.dayVal}
					onScrollChange={this._D_onScrollChange}
				>
					{getDaysInMonth(this.state.monthVal - 1, this.state.yearVal).map(
						(year, index) => {
							return (
								<Picker.Item
									className="my-picker-view-item"
									value={year}
									key={index}
								>
									{year}
								</Picker.Item>
							);
						},
					)}
				</Picker>
			</div>
		);
		return (
			<Wrap
				style={{
					paddingBottom: 0,
					height: '-webkit-fill-available',
					backgroundColor: 'rgb(248,248,248)',
				}}
			>
				<IndexWrap style={{ paddingTop: 56, backgroundColor: '#f8f8f8' }}>
					<NavBar>
						<div style={{ width: '50%' }}>
							<img
								src={back_ico}
								onClick={() => {
									history.goBack();
								}}
								style={{
									marginLeft: 19,
									width: 19,
									verticalAlign: 'middle',
									cursor: 'pointer',
								}}
							/>
						</div>
					</NavBar>
					{this.state.profile ? (
						<ContentWrap>
							<ProfileImgUp
								isDefaultProfileImg={this.state.profileImgURL ? false : true}
							>
								<div
									className="profile-image"
									style={{
										backgroundImage: `url('${
											this.state.profileImgURL
												? this.state.profileImgURL
												: default_profile
										}')`,
									}}
								/>
								<ImgBtn>
									<img width="18px" height="100%" src={imgAdd} /> <Dot />
									<input
										type="file"
										onChange={this.handleImage}
										accept="image/*, .heic, .heif"
									/>
								</ImgBtn>
							</ProfileImgUp>
							<p>닉네임</p>
							<div>
								{this.state.nicknameEditing ? (
									<IconWrapper
										onClick={() => {
											this.resetInput('nickname');
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="12"
											height="12"
											viewBox="0 0 12 12"
										>
											<path
												fill="#333"
												d="M11.24.769a.828.828 0 0 0-1.173 0L6 4.827 1.933.76A.828.828 0 1 0 .76 1.933L4.827 6 .76 10.067a.828.828 0 1 0 1.173 1.173L6 7.173l4.067 4.067a.828.828 0 1 0 1.173-1.173L7.173 6l4.067-4.067a.834.834 0 0 0 0-1.164z"
											/>
										</svg>
									</IconWrapper>
								) : null}
								<UpdateInput
									value={this.state.nickname}
									name="nickname"
									onChange={this.handleInput}
									placeholder="닉네임을 입력해주세요."
								/>
								{!this.state.nicknameValid ? (
									<ErrorMsg
										style={{
											marginTop: -40,
											paddingLeft: 10,
											marginBottom: 32,
										}}
									>
										이미 존재하는 닉네임입니다.
									</ErrorMsg>
								) : null}
							</div>
							<p>소개</p>
							<div>
								{this.state.introductionEditing ? (
									<IconWrapper
										onClick={() => {
											this.resetInput('introduction');
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="12"
											height="12"
											viewBox="0 0 12 12"
										>
											<path
												fill="#333"
												d="M11.24.769a.828.828 0 0 0-1.173 0L6 4.827 1.933.76A.828.828 0 1 0 .76 1.933L4.827 6 .76 10.067a.828.828 0 1 0 1.173 1.173L6 7.173l4.067 4.067a.828.828 0 1 0 1.173-1.173L7.173 6l4.067-4.067a.834.834 0 0 0 0-1.164z"
											/>
										</svg>
									</IconWrapper>
								) : null}
								<UpdateInput
									value={this.state.introduction}
									name="introduction"
									onChange={this.handleInput}
									placeholder="소개글을 입력해주세요."
								/>
							</div>
							<p>생년월일</p>
							<DropDownWrap>
								<Popup
									transitionName="rmc-picker-popup-slide-fade"
									maskTransitionName="rmc-picker-popup-fade"
									content={yearPopupContent}
									title="연도"
									dismissText=""
									okText=""
								>
									<MyDropDown style={{ lineHeight: '20px' }}>
										<span style={{ verticalAlign: 'sub' }}>
											{this.state.yearVal}
										</span>
										<IconWrapper>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="12"
												height="7"
												viewBox="0 0 12 7"
											>
												<path
													fill="#333"
													d="M11.29.29a.996.996 0 0 0-1.41 0L6 4.17 2.12.29A.996.996 0 1 0 .71 1.7L5.3 6.29c.39.39 1.02.39 1.41 0L11.3 1.7c.38-.38.38-1.02-.01-1.41z"
												/>
											</svg>
										</IconWrapper>
									</MyDropDown>
								</Popup>

								<Popup
									transitionName="rmc-picker-popup-slide-fade"
									maskTransitionName="rmc-picker-popup-fade"
									content={monthPopupContent}
									title="월"
									dismissText=""
									okText=""
								>
									<MyDropDown style={{ lineHeight: '20px' }}>
										{!this.state.monthVal ? (
											<BirthPlaceholder>01</BirthPlaceholder>
										) : (
											<BirthPlaceholder style={{ color: 'black' }}>
												{this.state.monthVal}
											</BirthPlaceholder>
										)}
										<IconWrapper>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="12"
												height="7"
												viewBox="0 0 12 7"
											>
												<path
													fill="#333"
													d="M11.29.29a.996.996 0 0 0-1.41 0L6 4.17 2.12.29A.996.996 0 1 0 .71 1.7L5.3 6.29c.39.39 1.02.39 1.41 0L11.3 1.7c.38-.38.38-1.02-.01-1.41z"
												/>
											</svg>
										</IconWrapper>
									</MyDropDown>
								</Popup>

								<Popup
									transitionName="rmc-picker-popup-slide-fade"
									maskTransitionName="rmc-picker-popup-fade"
									content={dayPopupContent}
									title="일"
									dismissText=""
									okText=""
								>
									<MyDropDown style={{ lineHeight: '20px' }}>
										{!this.state.dayVal ? (
											<BirthPlaceholder>01</BirthPlaceholder>
										) : (
											<BirthPlaceholder style={{ color: 'black' }}>
												{this.state.dayVal}
											</BirthPlaceholder>
										)}
										<IconWrapper>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="12"
												height="7"
												viewBox="0 0 12 7"
											>
												<path
													fill="#333"
													d="M11.29.29a.996.996 0 0 0-1.41 0L6 4.17 2.12.29A.996.996 0 1 0 .71 1.7L5.3 6.29c.39.39 1.02.39 1.41 0L11.3 1.7c.38-.38.38-1.02-.01-1.41z"
												/>
											</svg>
										</IconWrapper>
									</MyDropDown>
								</Popup>
							</DropDownWrap>

							<p>성별</p>
							<DropDownWrap>
								<Popup
									transitionName="rmc-picker-popup-slide-fade"
									maskTransitionName="rmc-picker-popup-fade"
									content={genderContent}
									title="성별"
									dismissText=""
									okText=""
								>
									<MyDropDown style={{ lineHeight: '20px' }}>
										<span style={{ verticalAlign: 'sub' }}>
											{this.state.gender === 'f' ? '여성' : '남성'}
										</span>
										<IconWrapper>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="12"
												height="7"
												viewBox="0 0 12 7"
											>
												<path
													fill="#333"
													d="M11.29.29a.996.996 0 0 0-1.41 0L6 4.17 2.12.29A.996.996 0 1 0 .71 1.7L5.3 6.29c.39.39 1.02.39 1.41 0L11.3 1.7c.38-.38.38-1.02-.01-1.41z"
												/>
											</svg>
										</IconWrapper>
									</MyDropDown>
								</Popup>
							</DropDownWrap>

							<p>SNS 연결</p>
							<p className="sns-title">
								<InstaIcon /> Instagram
							</p>
							<div style={{ position: 'relative' }}>
								{this.state.insta !== '' ? (
									<IconWrapper
										onClick={() => {
											this.resetInput('insta');
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="12"
											height="12"
											viewBox="0 0 12 12"
										>
											<path
												fill="#333"
												d="M11.24.769a.828.828 0 0 0-1.173 0L6 4.827 1.933.76A.828.828 0 1 0 .76 1.933L4.827 6 .76 10.067a.828.828 0 1 0 1.173 1.173L6 7.173l4.067 4.067a.828.828 0 1 0 1.173-1.173L7.173 6l4.067-4.067a.834.834 0 0 0 0-1.164z"
											/>
										</svg>
									</IconWrapper>
								) : null}
								<UpdateInput
									name="insta"
									value={this.state.insta}
									onChange={this.handleInput}
									placeholder="인스타그램 계정을 입력해주세요."
									style={{ marginBottom: 12 }}
								/>
							</div>

							<p className="sns-title">
								<YoutubeIcon /> Youtube
							</p>
							<div>
								{this.state.youtube !== '' ? (
									<IconWrapper
										onClick={() => {
											this.resetInput('youtube');
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="12"
											height="12"
											viewBox="0 0 12 12"
										>
											<path
												fill="#333"
												d="M11.24.769a.828.828 0 0 0-1.173 0L6 4.827 1.933.76A.828.828 0 1 0 .76 1.933L4.827 6 .76 10.067a.828.828 0 1 0 1.173 1.173L6 7.173l4.067 4.067a.828.828 0 1 0 1.173-1.173L7.173 6l4.067-4.067a.834.834 0 0 0 0-1.164z"
											/>
										</svg>
									</IconWrapper>
								) : null}
								<UpdateInput
									name="youtube"
									value={this.state.youtube}
									onChange={this.handleInput}
									placeholder="유튜브 계정 URL을 입력해주세요."
								/>
							</div>

							<p className="my-filter">
								마이필터
								{/*<span onClick={this.toFilterUpdate} className="my-filter-update-btn"> 업데이트 </span>*/}
							</p>
							<SettingBox>
								<p> 스타일 </p>
								<div style={{ display: 'block', marginBottom: 16 }}>
									{this.state.styleTags.map((tag, index) => {
										return (
											<StyleBtn
												key={index}
												handleStyleBtn={this.handleStyleBtn}
												data={tag}
												mode={'smallBtn'}
												myStyle={this.state.profile.style}
											/>
										);
									})}
								</div>
								<p> 신장 </p>
								<div style={{ display: 'block', marginBottom: 16 }}>
									<SignupFilterBtn
										clicked={this.state.stature == 155 ? true : false}
										handleBtn={this.handleStatureBtn}
										data={155}
										text={'~155'}
									/>
									<SignupFilterBtn
										clicked={this.state.stature == 160 ? true : false}
										handleBtn={this.handleStatureBtn}
										data={160}
										text={'~160'}
									/>
									<SignupFilterBtn
										clicked={this.state.stature == 165 ? true : false}
										handleBtn={this.handleStatureBtn}
										data={165}
										text={'~165'}
									/>
									<SignupFilterBtn
										clicked={this.state.stature == 170 ? true : false}
										handleBtn={this.handleStatureBtn}
										data={170}
										text={'~170'}
									/>
									<SignupFilterBtn
										clicked={this.state.stature == 175 ? true : false}
										handleBtn={this.handleStatureBtn}
										data={175}
										text={'~175'}
									/>
									<SignupFilterBtn
										clicked={this.state.stature == 180 ? true : false}
										handleBtn={this.handleStatureBtn}
										data={180}
										text={'175 이상'}
									/>
								</div>
								<p> 상의 사이즈 </p>
								<div style={{ display: 'block', marginBottom: 16 }}>
									<SignupFilterBtn
										clicked={this.state.size == 'XS' ? true : false}
										handleBtn={this.handleSizeBtn}
										data={'XS'}
										text={'XS'}
									/>
									<SignupFilterBtn
										clicked={this.state.size == 'S' ? true : false}
										handleBtn={this.handleSizeBtn}
										data={'S'}
										text={'S'}
									/>
									<SignupFilterBtn
										clicked={this.state.size == 'M' ? true : false}
										handleBtn={this.handleSizeBtn}
										data={'M'}
										text={'M'}
									/>
									<SignupFilterBtn
										clicked={this.state.size == 'L' ? true : false}
										handleBtn={this.handleSizeBtn}
										data={'L'}
										text={'L'}
									/>
									<SignupFilterBtn
										clicked={this.state.size == 'XL' ? true : false}
										handleBtn={this.handleSizeBtn}
										data={'XL'}
										text={'XL'}
									/>
								</div>
								<p> 하의 사이즈 </p>
								<div style={{ display: 'block', marginBottom: 16 }}>
									<SignupFilterBtn
										clicked={this.state.bottomSize == 'XS' ? true : false}
										handleBtn={this.handleBottomSizeBtn}
										data={'XS'}
										text={'XS'}
									/>
									<SignupFilterBtn
										clicked={this.state.bottomSize == 'S' ? true : false}
										handleBtn={this.handleBottomSizeBtn}
										data={'S'}
										text={'S'}
									/>
									<SignupFilterBtn
										clicked={this.state.bottomSize == 'M' ? true : false}
										handleBtn={this.handleBottomSizeBtn}
										data={'M'}
										text={'M'}
									/>
									<SignupFilterBtn
										clicked={this.state.bottomSize == 'L' ? true : false}
										handleBtn={this.handleBottomSizeBtn}
										data={'L'}
										text={'L'}
									/>
									<SignupFilterBtn
										clicked={this.state.bottomSize == 'XL' ? true : false}
										handleBtn={this.handleBottomSizeBtn}
										data={'XL'}
										text={'XL'}
									/>
								</div>

								<p> 피부타입 </p>
								<div style={{ display: 'block', marginBottom: 16 }}>
									<SignupFilterBtn
										clicked={this.state.skin == '건성' ? true : false}
										handleBtn={this.handleSkinBtn}
										data={'건성'}
										text={'건성'}
									/>
									<SignupFilterBtn
										clicked={this.state.skin == '지성' ? true : false}
										handleBtn={this.handleSkinBtn}
										data={'지성'}
										text={'지성'}
									/>
									<SignupFilterBtn
										clicked={this.state.skin == '복합성' ? true : false}
										handleBtn={this.handleSkinBtn}
										data={'복합성'}
										text={'복합성'}
									/>
									<SignupFilterBtn
										clicked={this.state.skin == '민감성' ? true : false}
										handleBtn={this.handleSkinBtn}
										data={'민감성'}
										text={'민감성'}
									/>
								</div>
							</SettingBox>
							<SubmitWrap>
								<SubmitBtn onClick={this.formSubmit}> 저장하기 </SubmitBtn>
							</SubmitWrap>
						</ContentWrap>
					) : (
						<LoadingSpinner />
					)}
				</IndexWrap>
			</Wrap>
		);
	}
}
export default MyProfileUpdate;
