import * as config from "axios/config";

export function openImagePicker(token) {
  if (config.isMobile.IOS()) {
    try {
      window.webkit.messageHandlers.openImagePicker.postMessage(token);
    } catch (err) {}
  } else {
    alert("해당 기능은 아이폰만 가능합니다.");
  }
}
export function loginKey(auth_token) {
  if (config.isMobile.IOS()) {
    try {
      window.webkit.messageHandlers.loginKey.postMessage(auth_token);
    } catch (err) {}
  } else {
    return false;
  }
}

export function getToken() {
  if (config.isMobile.IOS()) {
    try {
      window.webkit.messageHandlers.getToken.postMessage(
        "디바이스 내장 토큰 요청"
      );
    } catch (err) {}
  } else {
    return false;
  }
}

export function logout() {
  if (config.isMobile.IOS()) {
    try {
      window.webkit.messageHandlers.logOut.postMessage("로그아웃 요청");
    } catch (err) {}
  } else {
  }
}

export function kakaoLogin() {
  if (config.isMobile.IOS()) {
    try {
      window.webkit.messageHandlers.kakaoLogin.postMessage(
        "카카오 로그인 요청"
      );
    } catch (err) {}
  } else {
  }
}

export function googleLogin() {
  if (config.isMobile.IOS()) {
    try {
      window.webkit.messageHandlers.googleLogin.postMessage("구글 로그인 요청");
    } catch (err) {}
  } else {
  }
}
