import React, { Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { observer, inject } from 'mobx-react';

import FollowBtn from 'components/followBtn';

import InstaIcon from 'components/myIcons/instaIcon';
import YoutubeIcon from 'components/myIcons/youtubeIcon';

import history from 'utils/history';

import default_profile from 'images/default_profile.png';

const emerge = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const ProfileImagePhoto = styled.div`
	animation: ${emerge} 0.5s;
	animation-fill-mode: forwards;
`;
const ProfileWrap = styled.div`
	padding: 16px;
	margin-top: 45px;
	display: flex;
	flex-direction: row;
	align-items: center;
	p {
		margin: 0px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	p.profile-name {
		color: #333333;
		font-family: 'Noto Sans CJK KR';
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 4px;
		flex: 1;
	}
	p.profile-sns {
		color: #999999;
		font-family: 'Noto Sans CJK KR';
		font-size: 12px;
		font-weight: 500;
	}

	div.profile-sns {
		display: flex;
		flex-direction: row;
		align-items: center;
		.profile-sns-connect {
			color: #999999;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 500;
		}
	}
	span.profile-edit-btn {
		display: inline-block;
		text-align: center;
		width: 94px;
		height: 28px;
		border-radius: 14px;
		background-color: rgba(130, 143, 247, 0.2);

		color: #828ff7;
		font-family: 'Noto Sans CJK KR';
		font-size: 13px;
		font-weight: 700;
		line-height: 28px;
		cursor: pointer;
		hover: {
			opacity: 0.8;
		}
	}
	span.sns-link {
		cursor: pointer;
		:hover {
			opacity: 0.8;
		}
	}
`;
const ProfileImage = styled.div`
	width: 64px;
	height: 64px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	flex: 0 0 auto;
	margin-right: 16px;
	border-radius: 50%;
	display: flex;
	div {
		animation: ${emerge} 0.5s;
		animation-fill-mode: forwards;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		${props =>
			props.isDefaultProfileImg &&
			css`
				margin: auto;
				width: 32px;
				height: 32px;
				border-radius: 0;
			`}
	}
`;
const InfoCard = styled.div`
	width: 100%;
	height: 92px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ffffff;

	p {
		width: 100%;
		height: 47px;
		padding: 10px 16px;
		border-bottom: solid 1px #eeeeee;
		margin: 0;
		span.profile-grade {
			color: #bebebe;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 500;
			line-height: 30px;
		}
		span.profile-grade-data {
			margin-left: 16px;
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 700;
			line-height: 30px;
			text-transform: uppercase;
		}
		span.profile-grade-icon {
			float: right;
		}
	}
`;
const InfoCardInnerWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	padding: 16px 0px;
	div {
		text-align: center;
		width: 25%;
		span {
			display: block;
		}
		span.profile-info-title {
			color: #bebebe;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 500;
		}
		span.profile-info-data {
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			font-size: 24px;
			font-weight: 700;
		}
	}
`;

const ProfileIntro = styled.p`
	padding-left: 16px;
	margin-bottom: 8px;
	color: #999999;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
`;
const SocialIconWrap = styled.i`
	cursor: pointer;
	font-family: 'Noto Sans CJK KR';
	text-decoration: none;
	font-style: normal;
	color: #999999;
	font-weight: bold;
	font-size: 12px;
	:hover {
		opacity: 0.8;
	}
	.icon {
		margin-right: 4px;
	}
	svg {
		margin-right: 6px !important;
		margin-bottom: 0px;
	}
`;
@inject('userStore')
@observer
class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			data: {},
			myId: null,
			userProfileModalOn: false,
			notOnServiceModalOn: false,
			reportModalOn: false,
			loginRequiredModalOn: false,
			tabIdx: 0,
		};
	}
	async componentDidMount() {
		const id = this.props.id;
		if (id) {
			this.requestData(this.props);
		}
	}
	async componentWillReceiveProps(nextProps) {
		if (this.props.id !== nextProps.id && nextProps.id) {
			this.requestData(nextProps);
		}
	}
	toSns = url => {
		if (url) window.open(url, '_blank');
	};
	render() {
		const { data, isMine } = this.props;
		const toFollowing = `/profile/${data.id}/follow/following`;
		const toFollower = `/profile/${data.id}/follow/follower`;

		const instagram = data && data.instagram;
		const instaId =
			instagram && instagram.slice('http://instagram.com/'.length);
		const youtube = data && data.youtube;
		return (
			<Fragment>
				<ProfileWrap>
					<ProfileImage isDefaultProfileImg={data.image ? false : true}>
						<ProfileImagePhoto
							style={{
								backgroundImage: `url('${
									data.image ? data.image : default_profile
								}')`,
							}}
						/>
					</ProfileImage>
					<div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<p className="profile-name"> {data.nickname} </p>

							{isMine ? (
								<span
									className="profile-edit-btn"
									onClick={() => {
										history.push('/my-page/update');
									}}
								>
									내 정보 수정
								</span>
							) : (
								<div style={{ flex: '0 0 auto' }}>
									<FollowBtn profile={data} is_followed={data.is_followed} />
								</div>
							)}
						</div>
						<div className="profile-sns">
							<>
								{instagram && (
									<>
										<SocialIconWrap
											style={{ paddingTop: 6 }}
											onClick={() => {
												this.toSns(instagram);
											}}
										>
											<InstaIcon className="icon" mode="myProfile" />{' '}
											<span>{instaId}</span>
										</SocialIconWrap>
									</>
								)}
								{youtube && (
									<>
										<SocialIconWrap
											style={{
												paddingTop: 6,
												marginLeft: instagram ? 20 : 0,
											}}
											onClick={() => {
												this.toSns(youtube);
											}}
										>
											<YoutubeIcon className="icon" mode="myProfile" />
											<span>youtube</span>
										</SocialIconWrap>
									</>
								)}
							</>
						</div>
					</div>
				</ProfileWrap>
				<ProfileIntro> {data.introduction} </ProfileIntro>
				<div style={{ padding: 16, paddingTop: 0 }}>
					<InfoCard>
						<InfoCardInnerWrap>
							<div>
								<span className="profile-info-data"> {data.post} </span>
								<span className="profile-info-title"> 포스팅 </span>
							</div>
							<div>
								<span className="profile-info-data"> {data.like} </span>
								<span className="profile-info-title"> 좋아요 </span>
							</div>
							<div
								onClick={() => history.push(toFollowing)}
								style={{ cursor: 'pointer' }}
							>
								<span className="profile-info-data">{data.following}</span>
								<span className="profile-info-title"> 팔로잉 </span>
							</div>
							<div
								onClick={() => history.push(toFollower)}
								style={{ cursor: 'pointer' }}
							>
								<span className="profile-info-data">{data.follower}</span>
								<span className="profile-info-title"> 팔로워 </span>
							</div>
						</InfoCardInnerWrap>
					</InfoCard>
				</div>
			</Fragment>
		);
	}
}
export default Profile;
