import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Grid } from 'antd-mobile';
import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';
import MiniPostCard from 'components/mainCrtPost/miniPostCard';
import FilterDown from 'components/filterDown';
import Modal from 'components/modal';
import Home from 'pages/home_Prev';

import { ReactComponent as MyFilterIco } from 'images/my_filter.svg';

const Wrap = styled.div`
	padding-bottom: 24px;
	p {
		color: #333333;
		font-family: 'Noto Sans CJK KR';
		font-size: 16px;
		font-weight: 700;
		button {
			position: relative;
			padding: 0px 9px;
			float: right;
			height: 28px;
			border-radius: 50px;
			border: 2px solid rgba(130, 143, 247, 0.2);
			background-color: transparent;
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			font-size: 14px;
			font-weight: 500;
		}
	}
`;
const MyGrid = styled(Grid)`
	display: block;
	overflow: hidden;
	align-items: center;
	justify-content: center;

	div.am-flexbox-item.am-grid-item:nth-child(odd) {
		margin-right: 3%;
	}
`;

class PostResultList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			modalIndex: -1,
			modalThumbnail: '',
		};
	}
	componentDidMount() {
		window.onpopstate = this.closeModal;
	}
	closeModal = () => {
		this.setState({
			modalOpen: false,
		});
	};
	openModal = ({ index, thumbnail }) => {
		window.history.pushState(null, null, '/search/modal');

		this.setState({
			modalOpen: true,
			modalIndex: index,
			modalThumbnail: thumbnail,
		});
	};
	render() {
		let clickedPost;
		if (this.props.postData)
			clickedPost = this.props.postData[this.state.modalIndex];
		return (
			<Wrap style={{ display: this.props.displayNone ? 'none' : 'block' }}>
				{!this.props.postData ? (
					<SpinnerCurtain fixed />
				) : (
					<Fragment>
						{this.props.count != '' ? (
							<p style={{ height: '28px', lineHeight: '28px' }}>
								포스팅 {this.props.count}개
								<button style={{ border: 'none', paddingLeft: 19 }}>
									<MyFilterIco
										style={{ verticalAlign: 'middle' }}
										onClick={this.props.handleMyFilter}
									/>
								</button>
								<button
									style={{ lineHeight: '26px' }}
									onClick={this.props.toggleFilter}
								>
									{this.props.filterText}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="12"
										height="7"
										viewBox="0 0 12 7"
										style={{ marginLeft: 4 }}
									>
										<path
											fill="rgb(130,143,247)"
											d="M11.29.29a.996.996 0 0 0-1.41 0L6 4.17 2.12.29A.996.996 0 1 0 .71 1.7L5.3 6.29c.39.39 1.02.39 1.41 0L11.3 1.7c.38-.38.38-1.02-.01-1.41z"
										/>
									</svg>
									<FilterDown
										toggle={this.props.filterDownToggle}
										handleFilter={this.props.handleFilter}
									/>
								</button>
							</p>
						) : null}
						<MyGrid
							data={this.props.postData}
							columnNum={2}
							hasLine={false}
							activeStyle={false}
							itemStyle={{
								height: 232,
								marginTop: 12,
							}}
							renderItem={(post, index) => (
								<MiniPostCard
									key={post.id}
									data={JSON.stringify(post)}
									onClick={() => {
										this.openModal({
											index,
											thumbnail: post.thumbnail_image,
										});
									}}
								/>
							)}
						/>
					</Fragment>
				)}
				<Modal
					open={this.state.modalOpen}
					onClose={this.closeModal}
					afterClose={() => this.setState({ modalOpen: null })}
				>
					{clickedPost && (
						<div style={{ height: '100%', position: 'fixed', width: '100%' }}>
							<Home
								thumbnail={clickedPost.thumbnail_image}
								onpopstate={this.closeModal}
								onClose={this.closeModal}
								postId={clickedPost.id}
								swiperClassName="search-swiper-home"
								disableDefaultTransitionEvent
								postProps={{
									disableFooter: true,
									disableRecommendationAndFollowing: true,
									onClose: this.closeModal,
									posts: this.props.postData,
									id: clickedPost.id,
									loadMore: this.props.loadMore,
								}}
							/>
						</div>
					)}
				</Modal>
			</Wrap>
		);
	}
}
export default PostResultList;
