import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import CommentCard from 'components/commentCard';
import Backdrop from './backdrop';
import Wrapper from './wrapper';

import * as postAPI from 'axios/postAPI';

const Modal = styled.div`
	max-width: 480px;
	word-break: keep-all;
	padding: 16px;
	left: 0px;
	right: 0px;
	margin: auto;
	position: fixed;
	bottom: -600px;
	width: 100%;
	height: 475px;
	background: #ffffff;
	overflow: auto;
	padding-bottom: 76px;
	transition: 0.2s ease;
	z-index: 1002;
	${props =>
		props.active &&
		css`
			bottom: ${props.bottom ? props.bottom : '48px'};
			box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
			border-radius: 20px 20px 0 0;
		`}
	p {
		color: #333333;
		font-family: 'Noto Sans CJK KR';
		font-size: 16px;
		font-weight: 700;
	}
`;
const BtnWrap = styled.div`
	width: 100%;
	max-width: 480px;
	background-color: rgb(255, 255, 255);
	position: fixed;
	bottom: -600px;
	transition: 0.2s;
	margin: -16px;
	padding: 16px;
	margin-bottom: 0px;
	${props =>
		props.active &&
		css`
			bottom: ${props.bottom ? props.bottom : '48px'};
		`}
`;
const Btn = styled.div`
	display: block;
	width: 100%;
	height: 44px;
	box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
	border-radius: 68px;
	background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	line-height: 44px;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
const CommentForm = styled.div`
	position: fixed;
	width: 100%;
	max-width: 480px;
	height: 50px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	border: none;
	margin: -16px;
	margin-bottom: 0px;
	${props =>
		props.active &&
		css`
			display: block;
			bottom: ${props.bottom ? props.bottom : '48px'};
		`}
`;
const CommentInput = styled.textarea`
	width: 100%;
	height: 100%;
	border: none;
	padding: 16px;
	padding-right: 75px;
	::-webkit-scrollbar {
		display: none !important;
	}
`;
const ReplyInfoWrap = styled.div`
	position: absolute;
	display: none;
	top: -40px;
	width: 100%;
	max-width: 480px;
	height: 40px;
	border: none;
	padding: 12px;
	background-color: rgb(248, 248, 248);
	${props =>
		props.active &&
		css`
			display: block;
			bottom: ${props.bottom ? props.bottom : '48px'};
		`}
	p {
		font-size: 13px;
		font-weight: 400;
		color: #bebebe;
		margin: 0px;
	}
`;
const CommentSubmit = styled.button`
	border: none;
	text-align: center;
	width: 50px;
	height: 32px;
	border-radius: 20px;
	vertical-align: middle;
	font-family: 'Noto Sans CJK KR';
	font-size: 13px;
	font-weight: 700;
	line-height: 32px;
	cursor: pointer;
	position: absolute;
	right: 16px;
	top: 8px;
	background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
	color: #ffffff;
`;
const CommentWrap = styled.div`
	::-webkit-scrollbar {
		display: none;
	}
`;

@inject('userStore')
@inject('postStore')
@observer
class CommentModal extends Component {
	constructor() {
		super();
		this.state = {
			accessToken: false,
			comment: '',
			inputToggle: false,
			initData: false,
			replyToggle: false,
			replyComment: false,
		};
	}
	async componentDidMount() {
		const req = {
			post: this.props.postId,
			headers: this.props.userStore.auth_token
				? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
				: false,
		};
		if (req.post) {
			const commentData = await Promise.all([
				postAPI.getPostComments(req).then(res => {
					return res.data;
				}),
			]);
			this.setState({
				initData: commentData[0],
				inputToggle: false,
				replyToggle: false,
				replyComment: false,
			});
		}
	}

	async componentDidUpdate(prevProps) {
		if (prevProps != this.props) {
			const req = {
				post: this.props.postId,
				headers: this.props.userStore.auth_token
					? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
					: false,
			};
			const commentData = await Promise.all([
				postAPI.getPostComments(req).then(res => {
					return res.data;
				}),
			]);
			this.setState({
				initData: commentData[0],
				inputToggle: false,
				replyToggle: false,
				replyComment: false,
			});
		}
	}

	handleWrite = () => {
		const token = this.props.userStore.getTokenOrToggleLogin();
		if (token) {
			this.setState(
				_ => ({ inputToggle: true, accessToken: token }),
				() => {
					this.commentInput.focus();
				},
			);
		} else {
			this.props.handleClose();
		}
	};
	handleReply = parentComment => {
		const token = this.props.userStore.getTokenOrToggleLogin();
		if (!token) {
			this.props.handleClose();
		} else if (token && this.state.inputToggle == false) {
			this.setState(
				state => ({
					inputToggle: true,
					accessToken: token,
					replyToggle: true,
					replyComment: parentComment,
				}),
				() => {
					this.commentInput.focus();
				},
			);
		} else {
			this.setState({
				replyComment: parentComment,
			});
		}
	};
	handleCloseWrite = () => {
		if (this.state.inputToggle) {
			this.setState({
				inputToggle: false,
				replyToggle: false,
			});
		}
	};

	_onChangeComment = e => {
		this.setState({
			comment: e.target.value,
		});
	};

	handleSubmit = async () => {
		if (this.state.accessToken) {
			let req;
			if (this.state.replyToggle) {
				req = {
					parent: this.state.replyComment.id,
					data: { content: this.state.comment },
					headers: { Authorization: `Bearer ${this.state.accessToken}` },
				};
			} else {
				req = {
					post: this.props.postId,
					data: { content: this.state.comment },
					headers: { Authorization: `Bearer ${this.state.accessToken}` },
				};
			}
			const commentResult = await Promise.all([
				postAPI
					.createPostComment(req)
					.then(res => {
						return res.data;
					})
					.catch(e => {
						return false;
					}),
			]);
			if (commentResult[0]) {
				this.handleCloseWrite();
				req = {
					post: this.props.postId,
					headers: { Authorization: `Bearer ${this.state.accessToken}` },
				};
				const reFetchedData = await Promise.all([
					postAPI
						.getPostComments(req)
						.then(res => {
							return res.data;
						})
						.catch(e => {
							return false;
						}),
				]);
				if (reFetchedData[0]) {
					this.setState({
						initData: reFetchedData[0],
					});
					this.props.handleChange(reFetchedData[0].count);
				}
			}
		} else {
			this.props.handleClose();
			this.props.userStore.toggleLoginRequired();
		}
	};

	render() {
		const { handleClose, open } = this.props;
		return (
			<>
				<Backdrop open={open} handleClose={handleClose} />
				<Wrapper active={open}>
					<Modal active={open} bottom={this.props.bottom}>
						{this.state.initData ? (
							<Fragment>
								<CommentWrap
									style={{
										height: '100%',
										width: '100%',
										overflow: 'auto',
										overflowX: 'hidden',
									}}
									onClick={this.handleCloseWrite}
								>
									<p> {`댓글 ${this.state.initData.count}개`} </p>
									{this.state.initData.data.map((comment, index) => (
										<Fragment key={index}>
											{!comment.parent && (
												<CommentCard
													data={comment}
													handleReply={this.handleReply}
												/>
											)}
										</Fragment>
									))}
								</CommentWrap>
								<BtnWrap active={open} bottom={this.props.bottom}>
									{this.state.inputToggle ? (
										<CommentForm
											active={this.state.inputToggle}
											bottom={this.props.bottom}
										>
											<ReplyInfoWrap active={this.state.replyToggle}>
												{this.state.replyComment && (
													<p>
														{' '}
														{this.state.replyComment.profile.nickname}님에게
														답글 남기는 중{' '}
													</p>
												)}
											</ReplyInfoWrap>
											<CommentInput
												ref={commentInput => (this.commentInput = commentInput)}
												onChange={this._onChangeComment}
											/>

											<CommentSubmit onClick={this.handleSubmit}>
												{' '}
												게시{' '}
											</CommentSubmit>
										</CommentForm>
									) : (
										<Btn onClick={this.handleWrite}>댓글 쓰기</Btn>
									)}
								</BtnWrap>
							</Fragment>
						) : (
							<LoadingSpinner />
						)}
					</Modal>
				</Wrapper>
			</>
		);
	}
}

export default CommentModal;
