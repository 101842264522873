import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";


import * as config from "axios/config";

import * as myVideo from "utils/myVideo";
import * as iosHandler from "utils/iosHandler";

import { ReactComponent as VideoPlusIco } from "images/video_plus_ico.svg";

const MyVideoInputImg = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 72px;
  height: 128px;
  border-radius: 6px;
  background-color: #eeeeee;
  background-size: cover;
  background-position: center;
  margin-right: 8px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  video {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: black;
    object-fit: cover;
  }
  ${props =>
    props.added &&
    css`
      video {
        display: block;
      }
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    `}
  input {
    cursor: pointer;
  }
`;
const VideoDuration = styled.span`
  position: absolute;
  bottom: 6px;
  right: 6px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: #f5f5f5;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
`;
const AddBtnWrap = styled.div`
  position: absolute !important;
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  ${props =>
    props.added &&
    css`
      display: none;
    `}
`;
const AddBtn = styled.div`
  margin: auto;
  text-align: center;
  p {
    margin-bottom: 0px !important;
    color: #aeaeae;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 500;
  }
`;

@inject("userStore")
@observer
class VideoInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      added: false,
      video: false,
      videoUrl: "",
      duration: false,
      isIOSApp: config.isMobile.IOSWebView()
    };
  }
  componentDidMount() {
    window.app = this;
    if (this.props.video) {
      this.setState({
        added: true,
        videoUrl: this.props.video
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.video != this.props.video) {
      if (this.props.video) {
        this.setState({
          added: true,
          videoUrl: this.props.video
        });
      } else {
        this.setState({
          added: false,
          video: false,
          videoUrl: "",
          duration: false
        });
      }
    }
  }
  _onLoadedMetaData = e => {
    let duration = this.video.duration;
    duration = myVideo.secondsToTime(duration);
    const h = myVideo.pad(duration.h, 2);
    const m = myVideo.pad(duration.m, 2);
    const s = myVideo.pad(duration.s, 2);
    this.setState({
      duration: `${m}:${s}`
    });
  };
  _onPlay = () => {
    this.video.pause();
  };
  delVideo = () => {
    this.setState(
      {
        added: false,
        videoUrl: "",
        duration: false
      },
      () => this.props.delVideo(this.props.index)
    );
  };
  _addClickOnIOSApp = () => {
    iosHandler.openImagePicker(this.props.userStore.auth_token);
  };
  afterUpload = (id, url) => {
    const res = {
      id: id,
      url: url
    };
    this.props.addVideoInput(res);
  };

  render() {
    return (
      <MyVideoInputImg added={this.state.added}>
        <AddBtnWrap added={this.state.added}>
          <AddBtn onClick={this._addClickOnIOSApp}>
            <VideoPlusIco style={{ verticalAlign: "middle" }} />
          </AddBtn>
        </AddBtnWrap>
        <video
          ref={ref => (this.video = ref)}
          src={this.state.videoUrl}
          onLoadedMetadata={this._onLoadedMetaData}
          playsInline={true}
          muted={true}
          autoPlay={true}
          style={{
            minWidth: "100%",
            minHeight: "100%",
            position: "absolute"
          }}
          onPlay={this._onPlay}
        />
        {this.state.videoUrl ? (
          <svg
            onClick={this.delVideo}
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            style={{ position: "absolute", right: "8px", top: "8px" }}
          >
            <path
              fill="rgb(255,255,255)"
              d="M11.24.769a.828.828 0 0 0-1.173 0L6 4.827 1.933.76A.828.828 0 1 0 .76 1.933L4.827 6 .76 10.067a.828.828 0 1 0 1.173 1.173L6 7.173l4.067 4.067a.828.828 0 1 0 1.173-1.173L7.173 6l4.067-4.067a.834.834 0 0 0 0-1.164z"
            />
          </svg>
        ) : (
          <Fragment />
        )}
        <VideoDuration>
          {this.state.duration ? this.state.duration : ""}
        </VideoDuration>
      </MyVideoInputImg>
    );
  }
}
export default VideoInput;
