import React, { Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { observer, inject } from 'mobx-react';

import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';
import FollowBtn from 'components/followBtn';
import default_profile from 'images/default_profile.png';

const emerge = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const Wrap = styled.div``;
const ProfileWrap = styled.div`
	animation: ${emerge} 0.5s;
	animation-fill-mode: forwards;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 16px;
	p {
		margin: 0px !important;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	p.profile-name {
		color: #333333;
		font-family: 'Noto Sans CJK KR';
		font-size: 16px;
		font-weight: 700;
	}
	p.profile-intro {
		color: #999999;
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
		font-weight: 500;
	}
	span.profile-btn {
		display: inline-block;
		text-align: center;
		width: 64px;
		height: 28px;
		border-radius: 20px;
		vertical-align: middle;
		font-family: 'Noto Sans CJK KR';
		font-size: 13px;
		font-weight: 700;
		line-height: 28px;
		cursor: pointer;
		hover: {
			opacity: 0.8;
		}
	}
	span.profile-follow-btn {
		background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
		color: #ffffff;
	}
	span.profile-following-btn {
		background-color: rgba(130, 143, 247, 0.2);
		color: #828ff7;
	}
`;
const ProfileImage = styled.div`
	width: 56px;
	height: 56px;
	border-radius: 50%;
	background-size: cover;
	background-position: center;
	display: flex;
	margin-right: 12px;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
	${props =>
		props.isDefaultProfileImg &&
		css`
			background-color: white;
		`}
	div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		${props =>
			props.isDefaultProfileImg &&
			css`
				margin: auto;
				width: 32px;
				height: 32px;
				border-radius: 0;
			`}
	}
`;
@inject('userStore')
@observer
class UserResultList extends React.Component {
	userClick = id => {
		window.location.href = `/profile/${id}`;
	};
	render() {
		console.log(this.props.itemData);
		return (
			<Wrap>
				{this.props.itemData ? (
					<Fragment>
						{this.props.itemData.map((data, index) => (
							<ProfileWrap key={index}>
								<ProfileImage isDefaultProfileImg={data.image ? false : true}>
									<div
										style={{
											backgroundImage: `url('${
												data.image ? data.image : default_profile
											}')`,
										}}
										onClick={() => this.userClick(data.id)}
									/>
								</ProfileImage>
								<div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
									<p className="profile-name"> {data.nickname} </p>
									<p className="profile-intro"> {data.introduction} </p>
								</div>
								<FollowBtn
									accessToken={this.props.userStore.auth_token}
									profile={data}
									is_followed={data.is_followed}
								/>
							</ProfileWrap>
						))}
					</Fragment>
				) : (
					<SpinnerCurtain fixed />
				)}
			</Wrap>
		);
	}
}
export default UserResultList;
