import styled, { css } from 'styled-components';

/* css모음 */
export default styled.div`
	max-width: 1024px;
	min-height: 100vh;
	position: relative;
	margin: 0 auto;
	background: rgb(248, 248, 248);
	${props =>
		props.black &&
		css`
			background: rgb(0, 0, 0);
		`}
`;
