import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Grid } from 'antd-mobile';
import { Tabs } from 'antd-mobile';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import * as searchAPI from 'axios/searchAPI';
import MainCard from 'components/mainCrtPost/mainCard';
import Modal from 'components/modal';
import Home from 'pages/home_Prev';
import PostHeader from 'components/PostHeader';
import * as postAPI from 'axios/postAPI';
import * as userAPI from 'axios/userAPI';
import history from 'utils/history';
import Filter from '../components/filter';
import LoadingSpinner from '../components/loadingSpinner/loadingSpinner';

const Wrap = styled.div``;

const TabContentWrap = styled.div`
	width: 100%;
	max-width: 1024px;
	padding: 0px 16px;
	padding-bottom: 24px;
	padding-top: 60px;
`;
const MyGrid = styled(Grid)`
	display: block;
	// overflow: hidden;
	align-items: center;
	justify-content: center;
	margin-bottom: 48px;
`;
const Video = styled.video`
	position: absolute;
	z-index: -1;
	cursor: pointer;
	height: 100%;
	// padding-top: 132%;
	border-radius: 16px;
	object-fit: cover;
	left: 0;
	right: 0;
	width: 100%;
	top: 0;
`;
const TABS = ['포스팅', '상품', '사용자'];
@inject('userStore')
@observer
class SearchResult extends React.Component {
	constructor(props) {
		super(props);
		const isFollowing =
			typeof window !== 'undefined' &&
			window.location.href.includes('following=true');

		this.state = {
			isFollowing,
			currentTab: this.props.currentTab ? this.props.currentTab : '포스팅',
			filter: 'new',
			filterText: '최신순',
			filterDownToggle: false,
			data: null,
			page: 0,
			next: null,
			loading: false,
			modalOpen: false,
			newNoti: false,
			filterSelected: null,
		};
		this.scrollParent = React.createRef();
	}
	async componentDidMount() {
		this.props.handleTabChange(this.state.currentTab);
		window.onpopstate = this.closeModal;

		// noti
		const token = this.props.userStore.auth_token;
		if (token) {
			const req = {
				headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
			};
			const res = await userAPI.getIsNewNoti(req).then(res => {
				return res;
			});
			if (res.data) {
				this.setState({
					newNoti: res.data.is_new,
				});
			}
		}
		// 사파리에서 최소화시 비디오 자동재생이 종료된다.
		// 이를 해결한다.
		document.addEventListener('visibilitychange', e => {
			if (!document.hidden) {
				setTimeout(() => {
					const vids = document.querySelectorAll('.VIDEOS');
					vids.forEach(o => o.play());
				}, 1000);
			}
		});
	}
	componentWillReceiveProps(nextProps) {
		if (
			(nextProps.searchVal !== '' &&
				this.props.tagListOn &&
				!nextProps.tagListOn) ||
			(nextProps.searchVal === '' &&
				!nextProps.tagListOn &&
				this.props.searchVal !== '')
		) {
			this.setState({ data: null, page: 0, next: null });
		}
	}

	async componentDidUpdate(prevProps) {
		// user만 검색
		if (prevProps.searchVal !== this.props.searchVal) {
			if (this.state.currentTab === '사용자' && this.props.searchVal) {
				this.setState({
					data: [],
					count: 0,
					page: 0,
					next: null,
				});
			}
		}
	}
	handleMyFilter = () => {
		const token = this.props.userStore.getTokenOrToggleLogin();
		if (token) {
			this.props.userStore.toggleMyFilter();
		}
	};
	handleFilter = filter => {
		const dict = {
			popular: '인기순',
			new: '최신순',
		};
		this.setState({
			filter,
			filterText: dict[filter],
			filterDropDown: false,
			data: null,
			page: 0,
			next: null,
		});
	};
	toggleFilter = () => {
		this.setState({
			filterDownToggle: !this.state.filterDownToggle,
		});
	};
	_onChange = (tab, _) => {
		this.setState({
			currentTab: tab.title,
			data: null,
			page: 0,
			next: null,
		});
		this.props.handleTabChange(tab.title);
	};
	loadData = async ({ page, tab }) => {
		// this.setState({ loading: true });
		const headers = this.props.userStore.auth_token
			? {
					Authorization: `Bearer ${this.props.userStore.auth_token}`,
			  }
			: undefined;
		let result = await postAPI
			.getPostList({
				following: this.state.isFollowing,
				category: this.state.filterSelected,
				// profile: this.state.profileId,
				headers,
				next: this.state.next,
			})
			.then(res => res.data);
		// result = await searchAPI
		// 	.getPostList({
		// 		isFollowing: this.state.isFollowing,
		// 		tag: searchVal,
		// 		ordering: this.state.filter,
		// 		headers,
		// 		page,
		// 	})
		// 	.then(res => {
		// 		return res.data;
		// 	});
		let data = (this.state.data || []).concat(result.data);

		// DELETE DUPLICATE
		data = data.reduce((acc, val) => {
			if (!acc.find(o => o.id === val.id)) acc.push(val);
			return acc;
		}, []);

		// 탭을 빠르게 변환하여 탭 변환 이후에 리스트 데이터가 변화할 경우
		if (TABS[tab] === this.state.currentTab) {
			this.setState({
				data,
				count: result.count,
				page,
				next: result.next,
				// loading: false,
			});
		}
		return data;
	};
	closeModal = () => {
		this.setState({
			modalOpen: false,
		});
	};
	openModal = ({ index, thumbnail }) => {
		window.history.pushState(null, null, '/?modal=true');

		this.setState({
			modalOpen: true,
			modalIndex: index,
			modalThumbnail: thumbnail,
		});
	};

	handleClickPostHeader = name => {
		switch (name) {
			case 'search': {
				history.push('/search');
				break;
			}
			case 'noti': {
				if (this.props.userStore.getTokenOrToggleLogin()) {
					history.push('/notification');
				}
				break;
			}
			case 'recommendation': {
				window.location.href = '/';
				break;
			}
			case 'following': {
				const token = this.props.userStore.getTokenOrToggleLogin();
				if (token)
					if (!window.location.search.includes('following')) {
						if (window.location.search === '') {
							window.location.href = '/?following=true';
						} else {
							window.location.href = '&&following=true';
						}
					}
				break;
			}
			case 'detailMore': {
				this.setState({
					postMoreInfoOpen: true,
				});
				break;
			}
		}
	};
	loadMore = async () => {
		let initialPage;
		if (this.state.currentTab == '포스팅') initialPage = 0;
		else if (this.state.currentTab == '상품') initialPage = 1;
		else if (this.state.currentTab == '사용자') initialPage = 2;
		const data = await this.loadData({
			page: this.state.page + 1,
			tab: initialPage,
		});
		return data;
	};
	render() {
		const hasMore = this.state.page === 0 || Boolean(this.state.next);

		let clickedPost;
		if (this.state.data) clickedPost = this.state.data[this.state.modalIndex];

		return (
			<Wrap>
				<PostHeader
					disableRecommendationAndFollowing={
						this.props.disableRecommendationAndFollowing
					}
					isRecommendation={!this.state.isFollowing}
					onClick={this.handleClickPostHeader}
					newNoti={this.state.newNoti}
				/>
				<Filter
					selected={this.state.filterSelected}
					onClick={i =>
						this.setState({
							filterSelected: i,
							data: null,
							page: 0,
							next: null,
						})
					}
				/>
				<InfiniteScroll
					pageStart={this.state.page}
					loadMore={this.loadMore}
					hasMore={hasMore}
					loader={
						<div className="loader" style={{ textAlign: 'center' }} key={0}>
							<LoadingSpinner />
						</div>
					}
					useWindow={true}
				>
					<MyGrid
						data={this.state.data}
						columnNum={1}
						hasLine={false}
						activeStyle={false}
						itemStyle={{
							// height: 444,
							position: 'relative',
							width: '100%',
							paddingTop: '132%',
						}}
						renderItem={(post, index) => (
							<MainCard
								key={post.id}
								data={JSON.stringify(post)}
								onClick={() => {
									this.openModal({
										index,
										thumbnail: post.thumbnail_image,
									});
								}}
								isMain
							/>
						)}
					/>
				</InfiniteScroll>
				<Modal
					open={this.state.modalOpen}
					onClose={this.closeModal}
					afterClose={() => this.setState({ modalOpen: false })}
				>
					{clickedPost && (
						<div style={{ height: '100%', position: 'fixed', width: '100%' }}>
							<Home
								thumbnail={clickedPost.thumbnail_image}
								onpopstate={this.closeModal}
								onClose={this.closeModal}
								postId={clickedPost.id}
								swiperClassName="search-swiper-home"
								disableDefaultTransitionEvent
								postProps={{
									disableFooter: true,
									disableRecommendationAndFollowing: true,
									onClose: this.closeModal,
									posts: this.state.data,
									id: clickedPost.id,
									loadMore: this.loadMore,
								}}
							/>
						</div>
					)}
				</Modal>
			</Wrap>
		);
	}
}
export default SearchResult;
