import React from 'react';
import { observer, inject } from 'mobx-react';
import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import Footer from 'components/footer';
import TopNavBar from 'components/nav/navBar';
import LikeTabs from 'components/likeTabs';
import ItemLinkRequiredModal from 'components/popModal/itemLinkRequiredModal';
import ProductCategoryModal from 'components/popModal/productCategoryModal';
import history from 'utils/history';

@inject('userStore')
@observer
class MyLikePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			search_mode: false,
			productCategoryModalOpen: false,
		};
	}
	_searchOnClick = () => {
		this.setState({
			search_mode: !this.state.search_mode,
		});
	};
	handleClickAdd = () => {
		this.setState({
			productCategoryModalOpen: true,
		});
	};
	render() {
		const { initData } = this.state;
		return (
			<Wrap style={{ paddingBottom: 0, backgroundColor: 'rgb(248,248,248)' }}>
				<TopNavBar myLike={true} myLikeSearch={this._searchOnClick} />
				<ItemLinkRequiredModal bottom={'0px'} />
				<IndexWrap
					id="wrap"
					style={{
						paddingTop: 44,
						backgroundColor: '#f8f8f8',
					}}
				>
					<LikeTabs
						profile={initData}
						search_mode={this.state.search_mode}
						toggle={this._searchOnClick}
					/>
				</IndexWrap>
				<Footer
					location={this.props.location.pathname}
					onClickAdd={this.handleClickAdd}
				/>
				<ProductCategoryModal
					open={this.state.productCategoryModalOpen}
					handleClose={() =>
						this.setState({
							productCategoryModalOpen: false,
						})
					}
					onClick={selected => {
						if (this.props.userStore.getTokenOrToggleLogin())
							history.push(`/new-post?selected=${selected}`);
					}}
					bottom={'0px'}
				/>
			</Wrap>
		);
	}
}
export default MyLikePage;
