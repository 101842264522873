import React, { Component } from 'react';
import styled, { css } from 'styled-components';

class LikeIcon extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="18"
				viewBox="0 0 20 18"
				{...this.props}
			>
				<g>
					<g>
						<path d="M17.66.99c-2.64-1.8-5.9-.96-7.66 1.1C8.24.03 4.98-.82 2.34.99.94 1.95.06 3.57 0 5.28c-.14 3.88 3.3 6.99 8.55 11.76l.1.09c.76.69 1.93.69 2.69-.01l.11-.1C16.7 12.26 20.13 9.15 20 5.27c-.06-1.7-.94-3.32-2.34-4.28z" />
					</g>
				</g>
			</svg>
		);
	}
}

export default LikeIcon;
