import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import './style.css';

// Libs
import { ClipLoader } from 'react-spinners';

let Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute !important;
	top: 0px;
	left: 0px;
	display: flex;
	right: 0px;
	bottom: 0px;
	align-items: center;
	div {
		margin: auto;
	}
	${props =>
		props.fullSize &&
		css`
			z-index: 10000;
			height: 100vh;
			position: absolute !important;
			background-color: rgb(255, 255, 255);
		`}
	${props =>
		props.fixed &&
		css`
			position: fixed !important;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
		`}
`;

export default class SpinnerCurtain extends Component {
	componentDidMount() {}
	render() {
		return (
			<Wrapper fullSize={this.props.fullSize} fixed={this.props.fixed}>
				<ClipLoader
					sizeUnit={'px'}
					size={50}
					color={'rgb(130, 143, 247)'}
					loading={true}
				/>
			</Wrapper>
		);
	}
}
