import React, { Component } from 'react';
import styled, { css } from 'styled-components';

class HomeIcon extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="18"
				viewBox="0 0 20 18"
				{...this.props}
			>
				<g>
					<g>
						<path d="M7.718 16.807v-5.368h4.289v5.368c0 .59.482 1.074 1.072 1.074h3.217c.59 0 1.072-.483 1.072-1.074V9.292h1.823c.493 0 .729-.612.354-.934L10.58.274a1.08 1.08 0 0 0-1.437 0L.18 8.358a.535.535 0 0 0 .354.934h1.823v7.515c0 .59.482 1.074 1.072 1.074h3.217c.59 0 1.072-.483 1.072-1.074z" />
					</g>
				</g>
			</svg>
		);
	}
}

export default HomeIcon;
