import React, { Fragment } from 'react';
import Beforeunload from 'react-beforeunload';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';
import VideoCropSlider from 'components/videoCropSlider';
import VideoInput from 'components/videoInput';
import VideoEditResetIcon from 'components/myIcons/videoEditResetIcon';

import * as userAPI from 'axios/userAPI';
import * as eventAPI from 'axios/eventAPI';
import * as mediaAPI from 'axios/mediaAPI';
import history from 'utils/history';
import * as myVideo from 'utils/myVideo';

import back_ico from 'images/back_ico_b.svg';
import { ReactComponent as ImgAdd } from 'images/imgAdd.svg';
import sample_thumb from 'images/sample_thumb.png';

const NavBar = styled.div`
	display: flex;
	flex-direction: row;
	position: fixed;
	line-height: 44px;
	height: 44px;
	width: 100%;
	max-width: 1024px;
	z-index: 3;
	top: 0;
	background: rgb(248, 248, 248);
`;
const ContentWrap = styled.div`
	padding: 16px 24px;
	padding-bottom: 76px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 700;
	p {
		margin-bottom: 10px;
	}
	p.my-filter {
		span {
			float: right;
			color: #828ff7;
			font-family: 'Noto Sans CJK KR';
			font-size: 15px;
			font-weight: 700;
			line-height: 28px;
		}
	}
	span.my-filter-update-btn {
		cursor: pointer;
		:hover {
			opacity: 0.8;
		}
	}
	div {
		position: relative;
	}
	input::placeholder {
		color: #bebebe;
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
		font-weight: 500;
	}
`;
const SubmitBtn = styled.button`
	border: none;
	float: right;
	padding: 0px;
	background-color: transparent;
	width: 56px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 15px;
	font-weight: 700;
`;
const VideoWrap = styled.div`
	width: 100%;
	height: 408px;
	margin-bottom: 24px;
	video {
		object-fit: cover;
		position: absolute;
		width: 228px;
		height: 408px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
		border-radius: 20px;
	}
	div.video-player {
		width: 228px;
		height: 408px;
		margin: auto;
	}
`;
const InlineBtn = styled.button`
	border: none;
	float: right;
	text-align: right;
	padding: 0px;
	background-color: transparent;
	width: 56px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 15px;
	font-weight: 700;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
	margin-bottom: 14px;
`;
const CropWrap = styled.div`
	position: relative;
	margin: auto;
	width: 100%;
	display: flex;
	height: 56px;
	border-radius: 4px;
`;
const ThumbImg = styled.div`
	width: 32px;
	height: 56px;
	flex: 1 1 auto;
	background-size: cover;
	background-position: center;
`;

@inject('postStore')
@inject('userStore')
@observer
class VideoEditPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.match.params.id,
			init: false,
			video: false,
			videoUrl: '',
			duration: false,
			cropImg: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
			startPoint: 0,
			endPoint: 100,
			videoElement: undefined,
		};
	}
	componentWillMount() {
		if (!this.props.userStore.auth_token) history.push('/login');
		else if (this.props.postStore.addedVideoArr.length == 0)
			history.push('/new-post');
	}
	componentWillUnmount() {
		clearTimeout(this.state.autoPlayTimeout);
		this.video.currentTime = 0;
		this.video.pause();
		this.video.setAttribute('src', null);
		this.video.load();
		this.video = null;
	}
	componentDidMount() {
		if (this.props.postStore.addedVideoArr.length != 0) {
			this.setState({
				id: this.props.match.params.id,
				init: this.props.postStore.addedVideoArr[this.props.match.params.id]
					.init,
				video: this.props.postStore.addedVideoArr[this.props.match.params.id]
					.video,
				videoUrl: window.URL.createObjectURL(
					this.props.postStore.addedVideoArr[this.props.match.params.id].video,
				),
				videoType: this.props.postStore.addedVideoArr[
					this.props.match.params.id
				].video.type,
				videoPlayed: false,
			});
		}
	}
	getFrames = async () => {
		if (!this.props.postStore.addedVideoArr[this.props.match.params.id].init) {
			const formData = new FormData();
			formData.append('duration', this.state.durationVal);
			formData.append('video', this.state.video);
			const req = {
				headers: this.props.userStore.auth_token
					? {
							Authorization: `Bearer ${this.props.userStore.auth_token}`,
							'Content-Type': 'multipart/form-data',
					  }
					: false,
				data: formData,
			};
			const frames = await mediaAPI.getFramesFromMedia(req).then(res => {
				return res.data;
			});
			this.props.postStore.addedVideoArr[this.props.match.params.id].id =
				frames.id;
			this.props.postStore.addedVideoArr[this.props.match.params.id].frame =
				frames.frame;
			this.props.postStore.addedVideoArr[
				this.props.match.params.id
			].init = true;
			this.setState({
				init: true,
				autoPlayTimeout: setTimeout(() => {
					if (this.video) this.video.play();
				}, 1000),
			});
		} else {
			this.setState({
				autoPlayTimeout: setTimeout(() => {
					if (this.video) this.video.play();
				}, 1000),
			});
		}
	};
	_onLoadedMetaData = e => {
		const duration = this.video.duration;
		const refineDuration = myVideo.secondsToTime(duration);
		const h = myVideo.pad(refineDuration.h, 2);
		const m = myVideo.pad(refineDuration.m, 2);
		const s = myVideo.pad(refineDuration.s, 2);
		this.setState(
			{
				duration: `${m}:${s}`,
				durationVal: duration,
				videoElement: this.video,
			},
			() => {
				this.getFrames();
			},
		);
	};
	_onPlay = () => {
		let src = this.video.currentSrc;
		let error = this.video.error ? this.video.error.code : this.video.error;
		if (!error) {
			this.setState({
				videoPlayed: true,
			});
		}
		if (error == 3) {
			this.video.pause();
			this.video.currentTime = 0;
			this.video.load();
			this.video.play();
		}
	};
	_onWaiting = () => {
		this.setState({
			videoPlayed: false,
		});
	};
	resetCrop = () => {
		this.setState({
			resetClicked: true,
		});
	};
	afterResetCrop = () => {
		this.setState({
			resetClicked: false,
		});
	};
	_onCacnel = () => {
		this.props.postStore.addedVideoArr[this.state.id].start = undefined;
		this.props.postStore.addedVideoArr[this.state.id].startPos = undefined;
		this.props.postStore.addedVideoArr[this.state.id].end = undefined;
		this.props.postStore.addedVideoArr[this.state.id].endPos = undefined;
		history.goBack();
	};
	_onSubmit = () => {
		history.goBack();
	};
	render() {
		return (
			<Beforeunload onBeforeunload={() => "You'll lose your data!"}>
				<Wrap style={{ paddingBottom: 0, backgroundColor: 'rgb(248,248,248)' }}>
					{!this.state.init ? <SpinnerCurtain fullSize={true} /> : <Fragment />}
					<IndexWrap style={{ paddingTop: 44, backgroundColor: '#f8f8f8' }}>
						<NavBar>
							<div style={{ width: '50%' }}>
								<img
									src={back_ico}
									onClick={this._onCacnel}
									style={{
										marginLeft: 19,
										width: 19,
										verticalAlign: 'middle',
										cursor: 'pointer',
									}}
								/>
							</div>
							<div style={{ width: '50%' }}>
								<SubmitBtn onClick={this._onSubmit}> 완료 </SubmitBtn>
							</div>
						</NavBar>
						<ContentWrap>
							<VideoWrap>
								<div className="video-player">
									<video
										ref={ref => (this.video = ref)}
										src={this.state.videoUrl}
										onLoadedMetadata={this._onLoadedMetaData}
										playsInline={true}
										muted={true}
										autoPlay={false}
										onPlay={this._onPlay}
										loop={true}
										onWaiting={this._onWaiting}
										onPlaying={this._onPlay}
									/>
									{!this.state.videoPlayed ? <SpinnerCurtain /> : null}
								</div>
							</VideoWrap>
							<p>
								<InlineBtn onClick={this.resetCrop}>
									<VideoEditResetIcon />
								</InlineBtn>
							</p>
							{this.state.init ? (
								<CropWrap>
									{this.props.postStore.addedVideoArr[
										this.props.match.params.id
									].frame.map((img, index) => {
										let borderRadius;
										if (index == 0) borderRadius = '4px 0 0 4px';
										else if (
											index ==
											this.props.postStore.addedVideoArr[
												this.props.match.params.id
											].frame.length -
												1
										)
											borderRadius = '0 4px 4px 0';
										return (
											<ThumbImg
												key={index}
												style={{
													backgroundImage: `url('${img.image}')`,
													borderRadius: borderRadius,
												}}
											/>
										);
									})}
									<VideoCropSlider
										index={this.state.id}
										durationStr={this.state.duration}
										durationVal={this.state.durationVal}
										videoElement={this.state.videoElement}
										resetClicked={this.state.resetClicked}
										afterResetCrop={this.afterResetCrop}
									/>
								</CropWrap>
							) : (
								<LoadingSpinner />
							)}
						</ContentWrap>
					</IndexWrap>
				</Wrap>
			</Beforeunload>
		);
	}
}
export default VideoEditPage;
