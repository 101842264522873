import React from 'react';
import { observer, inject } from 'mobx-react';
import { Wrap } from 'components/style/custom-styled-components';
import ProductCategoryModal from 'components/popModal/productCategoryModal';
import MainList from 'containers/mainList';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import Footer from 'components/footer';
import * as userAPI from 'axios/userAPI';
import history from 'utils/history';

@inject('userStore')
@inject('cacheStore')
@observer
class SearchPage extends React.Component {
	constructor(props) {
		super(props);
		let clickedTag = undefined;
		if (
			typeof window !== 'undefined' &&
			window.location.search.includes('tag=')
		) {
			const { search } = window.location;
			clickedTag = decodeURIComponent(search.slice('?tag='.length));
		}
		this.state = {
			searchVal: clickedTag || '',
			currentTab: '포스팅',
			profile: undefined,
			clickedTag,
			tagListOn: false,
			productCategoryModalOpen: false,
		};
	}
	async componentDidMount() {
		if (this.state.clickedTag) {
			this.setState(
				{
					searchVal: this.state.clickedTag,
				},
				() => {
					this.handleSelectTag(this.state.clickedTag);
				},
			);
		}
		if (this.props.userStore.auth_token) {
			const profileReq = {
				headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
			};
			const profile = await userAPI.getMyUserProfile(profileReq).then(res => {
				return res.data;
			});
			this.setState({
				profile: profile,
			});
		}
	}
	handleTabChange = tab => {
		this.setState({
			currentTab: tab,
		});
	};
	handleSearch = event => {
		const { value } = event.target;
		this.setState({
			currentTab: this.state.currentTab,
			searchVal: value,
			tagListOn: value !== '',
		});
	};
	handleKeyPress = e => {
		const KeyCode = {
			ENTER: 13,
		};
		if (e.charCode == KeyCode.ENTER) {
			this.handleSubmitTag();
		}
	};
	handleSubmitTag = () => {
		const newRecentSearchArr = this.props.cacheStore.myRecentSearch.concat(
			this.state.searchVal,
		);
		this.props.cacheStore.updateMyRecentSearch(newRecentSearchArr);
	};
	handleSelectTag = searchVal => {
		this.setState(
			{
				searchVal,
				tagListOn: false,
			},
			() => {
				this.handleSubmitTag();
			},
		);
	};
	handleClickAdd = () => {
		this.setState({
			productCategoryModalOpen: true,
		});
	};
	refreshOnlyContents = () => {
		window.location.href = '/';
	};
	render() {
		return (
			<Wrap style={{ paddingBottom: 0 }}>
				<LoginRequiredModal bottom={'0px'} />
				<MainList
					currentTab={this.state.currentTab}
					handleSelectTag={this.handleSelectTag}
					handleTabChange={this.handleTabChange}
					searchVal={this.state.searchVal}
					tagListOn={this.state.tagListOn}
				/>
				<Footer
					location={this.props.location.pathname}
					refresh={this.refreshOnlyContents}
					onClickAdd={this.handleClickAdd}
				/>
				<ProductCategoryModal
					open={this.state.productCategoryModalOpen}
					handleClose={() =>
						this.setState({
							productCategoryModalOpen: false,
						})
					}
					onClick={selected => {
						if (this.props.userStore.getTokenOrToggleLogin())
							history.push(`/new-post?selected=${selected}`);
					}}
					bottom={'0px'}
				/>
			</Wrap>
		);
	}
}
export default SearchPage;
