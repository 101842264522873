import React, { Component } from "react";
import styled, { css } from "styled-components";

class DetailMoreIcon extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="11"
        viewBox="0 0 24 11"
      >
        <defs>
          <mask id="j3qdb" width="2" height="2" x="-1" y="-1">
            <path fill="#fff" d="M4 4h16v4H4z" />
            <path d="M12 4c.978 0 1.778.8 1.778 1.778s-.8 1.778-1.778 1.778-1.778-.8-1.778-1.778S11.022 4 12 4zM5.778 4c.978 0 1.778.8 1.778 1.778s-.8 1.778-1.778 1.778S4 6.756 4 5.778 4.8 4 5.778 4zm12.444 0C19.2 4 20 4.8 20 5.778s-.8 1.778-1.778 1.778-1.778-.8-1.778-1.778S17.244 4 18.222 4z" />
          </mask>
          <filter
            id="j3qda"
            width="46"
            height="34"
            x="-11"
            y="-11"
            filterUnits="userSpaceOnUse"
          >
            <feOffset in="SourceGraphic" result="FeOffset1063Out" />
            <feGaussianBlur
              in="FeOffset1063Out"
              result="FeGaussianBlur1064Out"
              stdDeviation="2 2"
            />
          </filter>
        </defs>
        <g>
          <g>
            <g filter="url(#j3qda)">
              <path
                fill="none"
                d="M12 4c.978 0 1.778.8 1.778 1.778s-.8 1.778-1.778 1.778-1.778-.8-1.778-1.778S11.022 4 12 4zM5.778 4c.978 0 1.778.8 1.778 1.778s-.8 1.778-1.778 1.778S4 6.756 4 5.778 4.8 4 5.778 4zm12.444 0C19.2 4 20 4.8 20 5.778s-.8 1.778-1.778 1.778-1.778-.8-1.778-1.778S17.244 4 18.222 4z"
                mask='url("#j3qdb")'
              />
              <path
                fillOpacity=".2"
                d="M12 4c.978 0 1.778.8 1.778 1.778s-.8 1.778-1.778 1.778-1.778-.8-1.778-1.778S11.022 4 12 4zM5.778 4c.978 0 1.778.8 1.778 1.778s-.8 1.778-1.778 1.778S4 6.756 4 5.778 4.8 4 5.778 4zm12.444 0C19.2 4 20 4.8 20 5.778s-.8 1.778-1.778 1.778-1.778-.8-1.778-1.778S17.244 4 18.222 4z"
              />
            </g>
            <path
              fill="#fff"
              d="M12 4c.978 0 1.778.8 1.778 1.778s-.8 1.778-1.778 1.778-1.778-.8-1.778-1.778S11.022 4 12 4zM5.778 4c.978 0 1.778.8 1.778 1.778s-.8 1.778-1.778 1.778S4 6.756 4 5.778 4.8 4 5.778 4zm12.444 0C19.2 4 20 4.8 20 5.778s-.8 1.778-1.778 1.778-1.778-.8-1.778-1.778S17.244 4 18.222 4z"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default DetailMoreIcon;
