import React from 'react';
import styled from 'styled-components';
import { TabBar } from 'antd-mobile';
import { Icon } from 'antd';
import { observer, inject } from 'mobx-react';

import HomeIcon from 'components/myIcons/homeIcon';
import ProfileIcon from 'components/myIcons/profileIcon';
import LikeIcon from 'components/myIcons/likeIcon';
import SearchIcon from 'components/myIcons/searchIcon';

import history from 'utils/history';

const PostTabBtn = styled.div`
	box-shadow: 0 2px 10px rgba(138, 99, 223, 0.5);
	background-image: linear-gradient(100deg, #828ff7 0%, #b08df7 100%);
	border-radius: 50%;
	width: 35px;
	height: 35px;
	cursor: pointer;
	margin-top: 1px;
`;
const FooterWrap = styled.div`
	left: 0;
	right: 0;
	margin: auto;
	z-index: 1000;
	position: fixed;
	bottom: -6px;
	width: 100%;
	max-width: 1024px;
`;

@inject('userStore')
@observer
class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 'homeTab',
			hidden: false,
			fullScreen: false,
		};
	}
	componentDidMount() {
		if (
			this.props.location == '/' ||
			this.props.location.includes('/following')
		) {
			this.setState({
				selectedTab: 'homeTab',
			});
		} else if (this.props.location.includes('/search')) {
			this.setState({
				selectedTab: 'searchTab',
			});
		} else if (this.props.location.includes('/my-like')) {
			this.setState({
				selectedTab: 'likeTab',
			});
		} else if (this.props.location.includes('/my-page')) {
			this.setState({
				selectedTab: 'userTab',
			});
		}
	}

	handleRequiredLoginNav = path => {
		if (this.props.userStore.getTokenOrToggleLogin()) {
			history.push(path);
		}
	};
	render() {
		const { selectedTab } = this.state;
		const isHome = selectedTab === 'homeTab';
		const iconStyle = { fill: 'rgba(255,255,255,0.4)' };
		const selectedIconStyle = { fill: 'rgba(255,255,255)' };
		const centerIconStyle = {
			boxShadow: 'none',
			backgroundImage: 'none',
			backgroundColor: 'rgba(255,255,255,0.4)',
		};
		return (
			<FooterWrap style={this.props.style}>
				<TabBar barTintColor={`rgba(255,255,255,1)`}>
					{/*1*/}
					<TabBar.Item
						key="Life"
						icon={<HomeIcon style={{}} opacity={0.2} />}
						selectedIcon={<HomeIcon opacity={'.7'} style={{}} />}
						selected={
							this.state.selectedTab === 'homeTab' &&
							!this.props.location.includes('/single')
						}
						onPress={() => {
							if (isHome) {
								this.props.refresh();
							} else {
								history.push('/');
							}
						}}
					></TabBar.Item>

					{/*2*/}
					<TabBar.Item
						icon={<SearchIcon style={{}} opacity={0.2} />}
						selectedIcon={<SearchIcon opacity=".7" />}
						key="Event"
						selected={this.state.selectedTab === 'searchTab'}
						onPress={() => history.push('/search')}
					></TabBar.Item>
					<TabBar.Item
						icon={
							<PostTabBtn style={{}}>
								{' '}
								<Icon
									type="plus"
									style={{
										fontSize: 15,
										color: 'rgb(255,255,255)',
										verticalAlign: 'text-top',
										height: 35,
										width: 35,
										lineHeight: '35px',
									}}
								/>{' '}
							</PostTabBtn>
						}
						selectedIcon={
							<PostTabBtn style={{}}>
								{' '}
								<Icon
									type="plus"
									style={{
										fontSize: 15,
										color: 'rgb(255,255,255)',
										verticalAlign: 'text-top',
										height: 35,
										width: 35,
										lineHeight: '35px',
									}}
								/>{' '}
							</PostTabBtn>
						}
						key="Post"
						selected={this.state.selectedTab === 'postTab'}
						onPress={() => {
							this.props.onClickAdd();
						}}
					></TabBar.Item>

					{/*3*/}
					<TabBar.Item
						icon={<LikeIcon style={{}} opacity={0.2} />}
						selectedIcon={<LikeIcon opacity=".7" />}
						key="Like"
						selected={this.state.selectedTab === 'likeTab'}
						onPress={() => {
							this.handleRequiredLoginNav('/my-like');
						}}
					></TabBar.Item>
					<TabBar.Item
						icon={<ProfileIcon style={{}} opacity={0.2} />}
						selectedIcon={<ProfileIcon opacity={0.7} />}
						key="User"
						selected={this.state.selectedTab === 'userTab'}
						onPress={() => {
							this.handleRequiredLoginNav('/my-page');
						}}
					></TabBar.Item>
				</TabBar>
			</FooterWrap>
		);
	}
}
export default Footer;
