import React, { Fragment } from 'react';
import Beforeunload from 'react-beforeunload';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import { animateScroll as scroll } from 'react-scroll';
import mime from 'mime';
import ProductAddPage from '../productAddPage';
import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import VideoInput from './iosVideoInput';
import ItemLinkAddComponent from 'components/itemLinkInput/itemLinkAddComponent';

import * as postAPI from 'axios/postAPI';
import * as userAPI from 'axios/userAPI';
import history from 'utils/history';
import closeIcon from 'images/baseline_close_black_18dp.png';
import LinkSvg from 'components/myIcons/linkIcon';

import axios from 'axios';
import categoryItems from 'utils/categoryItems';
import back_ico from 'images/back_ico_b.svg';
import DeleteModal from 'components/popModal/deleteModal';
import { ClipLoader, PropagateLoader } from 'react-spinners';

const NavBar = styled.div`
	display: flex;
	flex-direction: row;
	position: fixed;
	line-height: 56px;
	height: 44px;
	line-height: 44px;
	width: 100%;
	max-width: 1024px;
	z-index: 3;
	top: 0;
	background: rgb(248, 248, 248);
`;
const ContentWrap = styled.div`
	padding: 10px 16px;
	padding-bottom: 70px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 700;
	p {
		margin-bottom: 10px;
	}
	span.sub-btn {
		float: right;
		cursor: pointer;
		:hover {
			opacity: 0.8;
		}
	}
	div {
		position: relative;
	}
	input::placeholder {
		color: #bebebe;
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
		font-weight: 500;
	}
`;
const VideoInputWrap = styled.div`
	width: 100%;
	max-width: 1024px;
	border-radius: 6px;
	padding-bottom: 36px;
	display: flex;
	overflow-x: auto;
	::-webkit-scrollbar {
		display: none !important;
	}
	justify-content: center;
`;

const UpdateInput = styled.input`
	width: 100%;
	height: 44px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ffffff;
	border: none;
	margin-bottom: 36px;
	padding: 0px 16px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
`;
const SubmitWrap = styled.div`
	width: 100%;
	max-width: 1024px;
	margin: auto;
	padding: 16px;
	position: fixed !important;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-image: linear-gradient(
		to top,
		rgba(248, 248, 248, 1) 0%,
		rgba(255, 255, 255, 0) 100%
	);
`;
const SubmitBtn = styled.button`
	border: none;
	width: 100%;
	height: 44px;
	box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
	border-radius: 68px;
	background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
	${props =>
		props.disabled &&
		css`
			background-image: none;
			background-color: rgb(150, 150, 150);
		`}
`;
const ProductAddPageLayout = styled.div`
	position: absolute;
	top: 0px;
	width: 100%;
	height: 100%;
`;
const AddButton = styled.button`
	background: none;
	border: none;
	font-family: 'Noto Sans CJK KR';
	font-size: 15px;
	font-weight: 700;
	margin-top: 8px;
	color: #828ff7;
	width: 100%;
	text-align: center;
	cursor: pointer;
`;

@inject('postStore')
@inject('userStore')
@observer
class IosCreatePostPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			videoInputArr: [<Fragment />],
			videoLen: this.props.postStore.videoLen,
			videoFiles: this.props.postStore.iosAddedVideoArr,
			linkArr: this.props.postStore.createPost.links
				? this.props.postStore.createPost.links
				: [],
			linkNum: this.props.postStore.createPost.linkNum
				? this.props.postStore.createPost.linkNum
				: 1,
			tags: this.props.postStore.createPost.tags
				? this.props.postStore.createPost.tags
				: undefined,
			title: this.props.postStore.createPost.title
				? this.props.postStore.createPost.title
				: undefined,
			profile: this.props.postStore.createPost.profile
				? this.props.postStore.createPost.profile
				: undefined,
			deleteMode: false,
			productAddPageOn: false,
			productAdded: [],
			selectedProduct: null,

			loading: false,
			deleteIndex: -1,
		};
		if (typeof window !== 'undefined') {
			const selected = /selected=(\d)/.exec(window.location);
			if (selected) {
				let selectedCode = selected[1];
				this.categorySelected =
					selectedCode === '0'
						? 'fashion'
						: selectedCode === '1'
						? 'beauty'
						: 'hair';
			}
		}
		if (!this.categorySelected) {
			this.categorySelected = 'fashion';
		}
	}
	handleDeleteProduct = index => {
		let newProductAdded = this.state.productAdded.slice();
		newProductAdded.splice(index, 1);
		this.setState({
			productAdded: newProductAdded,
			deleteIndex: -1,
		});
	};
	getImgOfProduct = (label, category) => {
		const src = categoryItems[category].find(o => o.label === label).src;
		return src;
	};
	async componentWillMount() {
		if (!this.props.userStore.auth_token) history.push('/login');
		else if (!this.props.postStore.createPost.profile) {
			const req = {
				headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
			};
			const profile = await userAPI.getMyUserProfile(req).then(res => {
				return res.data;
			});
			this.setState({
				profile: profile,
			});
		}
	}
	componentDidMount() {
		let videoInputArr = [];
		for (var i = 0; i < this.state.videoLen; i++) {
			const video = this.props.postStore.iosAddedVideoArr[i]
				? this.props.postStore.iosAddedVideoArr[i].videoUrl
				: undefined;
			videoInputArr.push(
				<VideoInput
					key={i}
					index={i}
					onChange={this.handleInput}
					addVideoInput={this.addVideoInput}
					video={video}
					delVideo={this.delVideo}
				/>,
			);
		}
		this.setState({
			videoInputArr: videoInputArr,
		});
	}
	componentWillUnmount() {
		this.props.postStore.createPost = {
			title: this.state.title,
			tags: this.state.tags,
			links: this.state.linkArr,
			linkNum: this.state.linkNum,
			profile: this.state.profile,
		};
	}
	handleInput = event => {
		const eName = event.target.name;
		if (eName == 'tags') {
			var special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
			if (special_pattern.test(event.target.value) == true) {
				alert('태그에 특수문자는 입력할 수 없습니다.');
				this.setState({
					[eName]: this.state.tags ? this.state.tags : '',
				});
			} else {
				this.setState({ [eName]: event.target.value });
			}
		} else {
			this.setState({ [eName]: event.target.value });
		}
	};
	addVideoInput = res => {
		this.props.postStore.iosAddVideo(res);
		let videoInputArr = [];
		for (var i = 0; i < this.props.postStore.videoLen; i++) {
			const video = this.props.postStore.iosAddedVideoArr[i]
				? this.props.postStore.iosAddedVideoArr[i].videoUrl
				: undefined;
			videoInputArr.push(
				<VideoInput
					key={i}
					index={i}
					onChange={this.handleInput}
					addVideoInput={this.addVideoInput}
					video={video}
					delVideo={this.delVideo}
				/>,
			);
		}
		this.setState({
			videoInputArr: videoInputArr,
			videoFiles: this.props.postStore.iosAddedVideoArr,
			videoLen: this.props.postStore.videoLen,
		});
	};
	delVideo = index => {
		this.props.postStore.iosDelVideo(index);
		let videoInputArr = [];
		for (var i = 0; i < this.props.postStore.videoLen; i++) {
			const video = this.props.postStore.iosAddedVideoArr[i]
				? this.props.postStore.iosAddedVideoArr[i].videoUrl
				: undefined;
			videoInputArr.push(
				<VideoInput
					key={i}
					index={i}
					onChange={this.handleInput}
					addVideoInput={this.addVideoInput}
					video={video}
					delVideo={this.delVideo}
				/>,
			);
		}
		this.setState({
			videoInputArr: videoInputArr,
			videoFiles: this.props.postStore.iosAddedVideoArr,
			videoLen: this.props.postStore.videoLen,
		});
	};
	handleAddLink = () => {
		this.setState(
			{
				linkNum: this.state.linkNum + 1,
			},
			() => {
				scroll.scrollToBottom({
					duration: 500,
					delay: 0,
					smooth: true,
				});
			},
		);
	};
	toggleLinkDelete = () => {
		this.setState({
			deleteMode: !this.state.deleteMode,
		});
	};
	handleAddLinkArr = (id, data) => {
		const { linkArr } = this.state;
		let isNew = true;
		for (var i = 0; i < this.state.linkArr.length; i++) {
			if (this.state.linkArr[i].id == id) {
				isNew = false;
				break;
			}
		}
		if (isNew) {
			const { linkArr } = this.state;
			this.setState({
				linkArr: linkArr.concat({ id: id, data }),
			});
		} else {
			this.setState({
				linkArr: linkArr.map(
					arr =>
						id === arr.id
							? { ...arr, data } // 새 객체를 만들어서 기존의 값과 전달받은 data 을 덮어씀
							: arr, // 기존의 값을 그대로 유지
				),
			});
		}
	};
	handleRemoveLinkArr = id => {
		const { linkArr } = this.state;
		this.setState({
			linkArr: linkArr.filter(arr => arr.id !== id),
		});
	};
	handleTagArr = arr => {
		this.setState({
			tagArr: arr,
		});
	};
	formSubmit = async () => {
		try {
			this.props.postStore.setLinkLoading(true);
			if (this.props.postStore.iosAddedVideoArr.length == 0) {
				alert('영상을 하나 이상 업로드해야 합니다');
			} else if (!this.state.title) {
				alert('제목을 입력해 주세요');
			} else if (!this.state.tags) {
				alert('태그를 입력해 주세요');
			} else if (this.state.productAdded.length === 0) {
				alert('상품 정보를 추가해주세요.');
			} else {
				this.setState({ loading: true });
				const tags = this.state.tags.replace(/ /gi, '').split(',');

				// 스타일 태그 추가
				if (tags.indexOf('스타일') < 0) {
					tags.unshift('스타일');
				}

				let links = [];
				for (var i = 0; i < this.state.linkArr.length; i++) {
					links.push(this.state.linkArr[i].data);
				}
				const req = {
					data: {
						title: this.state.title,
						tags: tags,
						links: links,
						video: this.props.postStore.iosAddedVideoArr,
						category: this.state.productAdded[0].category,
					},
					headers: {
						Authorization: `Bearer ${this.props.userStore.auth_token}`,
					},
				};
				if (this.state.productAdded.length) {
					req.data.links = this.state.productAdded.map(o => ({
						shopName: o.shop,
						shopLink: o.link,
						link: o.link,
						category: o.category,
						label: o.label,
					}));
				}

				// IOS는 업로드가 되어있어 파일 원본이 없고 URL만 남아있다.
				// 파일을 수동으로 생성을 해주어야 한다.
				try {
					const url = req.data.video[0].videoUrl;
					const fetched = await fetch(url);
					const blob = await fetched.blob();
					const filename = url.slice(url.lastIndexOf('/') + 1, url.length);
					const file = new File([blob], filename, {
						type: mime.getType(filename),
					});
					const formData = new FormData();
					formData.append('video', file);
					const { data } = await axios.post(
						`http://api.dev.lay-er.me/node/boomerang`,
						formData,
					);
					req.data.boomerang = data.key;
				} catch (e) {
					console.error(e);
				}
				await postAPI.createPostApp(req).then(res => {
					return res.data;
				});
				this.setState(
					{
						linkArr: [],
						linkNum: 1,
						tags: undefined,
						title: undefined,
					},
					this.props.postStore.clearNewPost,
				);
				history.push('/following');
			}
			this.props.postStore.setLinkLoading(false);
			this.setState({ loading: false });
		} catch (e) {
			// alert(`오류 : ${e.message}`);
			console.error(e.message);
			history.push('/following');
			this.props.postStore.setLinkLoading(false);
			this.setState({ loading: false });
		}
	};
	render() {
		let videoInputArr = [];
		for (var i = 0; i < this.state.videoLen; i++) {
			const videoUrl = this.props.postStore.iosAddedVideoArr[i]
				? this.props.postStore.iosAddedVideoArr[i].videoUrl
				: undefined;
			videoInputArr.push(
				<VideoInput
					key={i}
					index={i}
					onChange={this.handleInput}
					addVideoInput={this.addVideoInput}
					video={videoUrl}
					delVideo={this.delVideo}
				/>,
			);
		}
		return (
			<Beforeunload onBeforeunload={() => '변경 사항이 저장되지 않습니다.'}>
				<Wrap style={{ paddingBottom: 0, backgroundColor: 'rgb(248,248,248)' }}>
					<IndexWrap style={{ paddingTop: 56, backgroundColor: '#f8f8f8' }}>
						<NavBar>
							<div style={{ width: '50%' }}>
								<img
									src={back_ico}
									onClick={() => {
										history.push('/');
									}}
									style={{
										marginLeft: 19,
										width: 19,
										verticalAlign: 'middle',
										cursor: 'pointer',
									}}
								/>
							</div>
							{/* IOS 웹뷰 미리보기 페이지 버그로 인해 기능 OFF
              <div style={{width:'50%', textAlign:'right', paddingRight:15}}>
                <ToSampleBtn onClick={()=>{history.push('/new-post/sample')}}>미리보기</ToSampleBtn>
              </div>
              */}
						</NavBar>
						<ContentWrap>
							<p>영상</p>
							<VideoInputWrap>{this.state.videoInputArr}</VideoInputWrap>
							<p>제목</p>
							<div>
								<UpdateInput
									ref={ref => this.titleInput}
									name="title"
									value={this.state.title}
									onChange={this.handleInput}
									placeholder="제목을 입력해주세요."
								/>
							</div>
							<p>태그</p>
							<UpdateInput
								ref={ref => this.tagInput}
								name="tags"
								value={this.state.tags}
								onChange={this.handleInput}
								placeholder="예시 : 스타일링, 헤어팁, 메이크업"
							/>
							<p>상품 정보</p>
							{this.state.productAdded.map((product, index) => (
								<div
									key={product.id}
									style={{
										display: 'flex',
										paddingTop: 12,
										paddingBottom: 12,
										borderBottom: '1px solid #dfdfdf',
									}}
									role="button"
									onClick={() => {
										this.setState({
											selectedProduct: product,
											label: product.label,
											shop: product.shop,
											link: product.link,
											productAddPageOn: true,
										});
									}}
								>
									<div style={{ marginRight: 12 }}>
										<img
											src={this.getImgOfProduct(
												product.label,
												product.category,
											)}
											style={{ height: 64, width: 64 }}
										/>
									</div>
									<div
										style={{
											flex: 1,
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<div
											style={{
												fontSize: 13,
												marginBottom: 2,
											}}
										>
											{product.label}
										</div>
										<div
											style={{
												fontSize: 14,
												fontWeight: 'bold',
												display: 'flex',
											}}
										>
											{product.shop}{' '}
											{product.link && product.link !== '' && (
												<div
													style={{
														marginLeft: 8,
														color: '#828ff7',
														display: 'flex',
														cursor: 'pointer',
													}}
													role="button"
													onClick={e => {
														e.preventDefault();
														e.stopPropagation();
														window.open(product.link);
													}}
												>
													<LinkSvg
														style={{
															width: 20,
															height: 20,
															fill: '#828ff7',
														}}
													/>
													링크
												</div>
											)}
										</div>
									</div>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
										role="button"
										onClick={e => {
											e.preventDefault();
											e.stopPropagation();
											this.setState({
												deleteIndex: index,
											});
										}}
									>
										<img src={closeIcon} style={{ width: 24, height: 24 }} />
									</div>
								</div>
							))}
							<AddButton
								onClick={() => {
									this.setState({
										productAddPageOn: true,
										selectedProduct: null,
									});

									window.history.pushState(null, null, '?select=true');
								}}
								role="button"
							>
								추가하기
							</AddButton>
							<SubmitWrap>
								<SubmitBtn
									onClick={this.formSubmit}
									disabled={this.props.postStore.linkLoading}
								>
									{' '}
									게시하기{' '}
								</SubmitBtn>
							</SubmitWrap>
						</ContentWrap>
					</IndexWrap>
				</Wrap>
				{this.state.productAddPageOn && (
					<ProductAddPageLayout>
						<ProductAddPage
							onBack={() => this.setState({ productAddPageOn: false })}
							onSubmit={submitted => {
								if (this.state.selectedProduct) {
									const productAddedSliced = this.state.productAdded.slice();
									const idx = productAddedSliced.findIndex(
										o => o.id === this.state.selectedProduct.id,
									);
									productAddedSliced[idx] = {
										...submitted,
										id: this.state.selectedProduct.id,
										category: this.state.selectedProduct.category,
									};
									this.setState({
										productAdded: productAddedSliced,
										productAddPageOn: false,
									});
								} else {
									this.setState({
										productAdded: this.state.productAdded.concat({
											...submitted,
											id: `${Math.random()}`,
											category: this.categorySelected,
										}),
										productAddPageOn: false,
									});
								}
							}}
							categoryItems={categoryItems[
								this.state.selectedProduct
									? this.state.selectedProduct.category
									: this.categorySelected
							].map(o => o.label)}
							selectedProduct={this.state.selectedProduct}
						/>
					</ProductAddPageLayout>
				)}
				{this.state.loading && (
					<div
						style={{
							position: 'absolute',
							top: 0,
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<ClipLoader
							sizeUnit={'px'}
							size={36}
							color={'rgb(130, 143, 247)'}
							loading={true}
						/>
					</div>
				)}
				<DeleteModal
					active={this.state.deleteIndex > -1}
					onClick={() => this.handleDeleteProduct(this.state.deleteIndex)}
					handleClose={() => this.setState({ deleteIndex: -1 })}
				/>
			</Beforeunload>
		);
	}
}
export default IosCreatePostPage;
