import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import PostDeleteModal from 'components/popModal/postDeleteModal';
import Backdrop from './backdrop';
import * as postAPI from 'axios/postAPI';
import history from 'utils/history';
import Wrapper from './wrapper';

const Modal = styled.div`
	max-width: 480px;
	display: flex;
	flex-direction: column;
	position: fixed;
	left: 0px;
	right: 0px;
	margin: auto;
	bottom: -350px;
	width: 100%;
	height: 227px;
	background-color: #ffffff;
	transition: 0.2s ease;
	z-index: 1002;
	${props =>
		props.active &&
		css`
			bottom: ${props.bottom ? props.bottom : '48px'};
			box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
			border-radius: 20px 20px 0 0;
		`}
	${props =>
		props.isMine &&
		css`
			height: 284px;
		`}
  p:not(:last-child) {
		border-bottom: solid 1px #ebebeb;
	}
`;
const ModalItem = styled.p`
	width: 100%;
	padding: 18px 16px;
	text-align: center;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	margin: 0px;
	cursor: pointer;
`;

@inject('userStore')
@inject('postStore')
@observer
class PostMoreInfoModal extends Component {
	constructor() {
		super();
		this.state = {};
	}
	copyToClipboard = e => {
		if (document.queryCommandSupported('copy')) {
			if (navigator.userAgent.match(/ipad|ipod|iphone|iPad|iPod|iPhone/i)) {
				var el = this.textArea;
				el.contentEditable = true;
				// el.readOnly = false;
				var range = document.createRange();
				range.selectNodeContents(el);
				var sel = window.getSelection();
				sel.removeAllRanges();
				sel.addRange(range);
				el.setSelectionRange(0, 999999);
			} else {
				this.textArea.select();
			}
			document.execCommand('copy');
			console.log(this.textArea.value);
			alert('링크 복사에 성공하였습니다.');
			this.props.handleClose();
			window.getSelection().removeAllRanges();
		} else {
			alert('해당 브라우저에서 링크 복사기능을 지원하지 않습니다.');
		}
	};

	kakaoSendLink = data => {
		window.Kakao.Link.sendDefault({
			objectType: 'feed',
			content: {
				title: data.title,
				imageUrl: data.thumbnail_image,
				link: {
					mobileWebUrl: window.location.href + this.props.post.id,
					webUrl: window.location.href + this.props.post.id,
				},
			},
		});
	};
	deletePost = async () => {
		const req = {
			id: this.props.post.id,
			headers: this.props.userStore.auth_token
				? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
				: false,
		};
		await postAPI.deletePost(req).then(res => {
			return res.data;
		});
		this.props.handleClose();
		this.props.postStore.togglePostDelete();
		// history.push('/');
		// window.location.reload();
		window.location.href = '/';
	};
	_toggleDelete = () => {
		this.props.postStore.togglePostDelete();
	};
	toReport = () => {
		const reportData = {
			mode: 'post',
			id: this.props.post.id,
		};
		// this.props.postStore.toggleMoreInfo()
		this.props.userStore.setReportData(reportData);
		this.props.userStore.toggleReport();
	};
	toEdit = () => {
		this.props.handleClose();
		this.props.userStore.clearAllToggle();
		// this.props.postStore.setEditPost()
		history.push(`/edit-post/${this.props.post.id}`);
	};
	render() {
		const { open, post, handleClose } = this.props;
		const {
			location: { origin },
		} = window;
		return (
			<>
				<Backdrop open={open} handleClose={handleClose} />
				<Wrapper active={open}>
					<Modal
						isMine={this.props.isMine}
						active={open}
						bottom={this.props.bottom}
					>
						{this.props.isMine ? (
							<ModalItem
								onClick={this._toggleDelete}
								style={{ color: '#f85e3a' }}
							>
								{' '}
								삭제{' '}
							</ModalItem>
						) : null}
						{this.props.isMine ? (
							<ModalItem onClick={this.toEdit}> 수정하기 </ModalItem>
						) : null}
						<ModalItem onClick={this.copyToClipboard}> 링크 복사 </ModalItem>
						<ModalItem
							onClick={() => {
								this.kakaoSendLink(this.props.post);
							}}
						>
							{' '}
							카카오톡에 공유하기{' '}
						</ModalItem>
						<ModalItem onClick={this.toReport}> 신고하기 </ModalItem>
						<ModalItem onClick={handleClose}> 취소 </ModalItem>
					</Modal>
					<Fragment>
						<textarea
							ref={textarea => (this.textArea = textarea)}
							readOnly
							value={`${origin}/${post && post.id}`}
							style={{
								width: 0,
								height: 0,
								overflow: 'hidden',
								position: 'fixed',
								top: '-300px',
							}}
						/>
					</Fragment>
					<PostDeleteModal deletePost={this.deletePost} />
				</Wrapper>
			</>
		);
	}
}

export default PostMoreInfoModal;
