// 테스트 API 서버 URL
// export const ROOT_URL = "http://api.dev.jangwon.io"

// export const ROOT_URL = "http://api.localhost:8000"

//실서버 API URL
export const ROOT_URL = 'http://api.dev.lay-er.me';

/* DEPLOY IN package.json
  // 테스트서버

  "deploy": "aws s3 sync build/ s3://sample.movedot",
  // 실서버
  "deploy": "aws s3 sync build/ s3://lay-er.me --profile movedot",
*/

export const NAVER_KEY = 'qjkBmYQs1zswdsUHl7cx';
export const KAKAO_JS_KEY = '94e1ed4175e0d5963d60975e63bff30f';
export const KAKAO_REST_KEY = 'd951ed60a7012d18a00ee26375e19ab4';
export const GOOGLE_KEY =
	'1081452037918-2plovs6fqnsg49ms4nougest1m80ncb0.apps.googleusercontent.com';
export const GOOGLE_IOS_KEY =
	'1081452037918-3mat2qephl6gd7sddrcofhh40915gdge.apps.googleusercontent.com';

export var isMobile = {
	Android: function() {
		return navigator.userAgent.match(/Android/i) == null ? false : true;
	},
	BlackBerry: function() {
		return navigator.userAgent.match(/BlackBerry/i) == null ? false : true;
	},
	IOS: function() {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null
			? false
			: true;
	},
	IOSWebView: function() {
		return navigator.userAgent.match(/WKWebView/i) == null ? false : true;
	},
	Opera: function() {
		return navigator.userAgent.match(/Opera Mini/i) == null ? false : true;
	},
	Windows: function() {
		return navigator.userAgent.match(/IEMobile/i) == null ? false : true;
	},
	any: function() {
		return (
			isMobile.Android() ||
			isMobile.BlackBerry() ||
			isMobile.IOS() ||
			isMobile.Opera() ||
			isMobile.Windows()
		);
	},
};
