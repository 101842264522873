import { observable, action } from 'mobx';
import * as postAPI from 'axios/postAPI';

export default class PostStore {
	@observable moreToggle = false;
	@observable commentToggle = false;
	@observable deleteToggle = false;
	@observable.ref addedVideoArr = [];
	@observable.ref iosAddedVideoArr = [];
	@observable.ref editedVideoArr = [];
	@observable.ref iosEditedVideoArr = [];
	@observable.ref createPost = {
		title: undefined,
		tags: undefined,
		links: [],
		linkNum: undefined,
		thumbnail_image: undefined,
	};
	@observable videoLen = 1;
	@observable editedVideoLen = 1;
	@observable linkLoading = false;

	// 포스팅 등록 / 수정 시 링크 로딩 상태
	@action setLinkLoading = b => {
		this.linkLoading = b;
	};

	// 모든 토글 끄기
	@action clearAllToggle = () => {
		this.moreToggle = false;
		this.commentToggle = false;
		this.deleteToggle = false;
	};

	isFetchedWelcomeMsg = false;
	isFetchedCrtTabs = false;
	isFetchedMainTabs = false;
	postDataDict = {};

	@action getPost = async req => {
		const postData = await Promise.all([
			postAPI
				.getPost(req)
				.then(res => {
					return res.data;
				})
				.catch(e => {
					alert(e);
					return false;
				}),
		]);
		if (postData[0]) {
			return postData[0];
		} else {
			return false;
		}
	};

	@action getPostList = async ({
		tag,
		following = false,
		ordering = false,
		headers = undefined,
		next = null,
	}) => {
		let reqTag;
		if (tag == '전체') {
			reqTag = '';
		} else {
			try {
				reqTag = tag.split('#')[1];
			} catch (e) {
				//console.log(e)
			}
		}
		let req = {
			tag: reqTag,
			following,
		};
		if (ordering) {
			req['ordering'] = ordering;
		}
		if (headers) {
			req['headers'] = headers;
		}
		if (next) {
			// 다음 페이지 요청 ENDPOINT URL
			req['next'] = next;
		}
		let data = {};
		// crtPostData에 next 키가 있다.
		// 이건 다음 페이지 요청 URL이다.
		const crtPostData = await Promise.all([
			postAPI
				.getPostList(req)
				.then(res => {
					return res.data;
				})
				.catch(e => {
					return false;
				}),
		]);

		if (crtPostData[0]) {
			data[tag] = crtPostData[0].data;
			return { data: data[tag], next: crtPostData[0].next };
		} else {
			return false;
		}
	};
	@action getCrtTab = async () => {
		if (!this.isFetchedCrtTabs) {
			const crtTabs = await Promise.all([
				postAPI
					.getCrtTags()
					.then(res => {
						return res.data.data;
					})
					.catch(e => {
						alert(e);
						return false;
					}),
			]);
			this.isFetchedCrtTabs = crtTabs;
			return crtTabs;
		} else {
			return this.isFetchedCrtTabs;
		}
	};

	@action getMainTag = async () => {
		if (!this.isFetchedMainTabs) {
			const mainTags = await Promise.all([
				postAPI
					.getMainTags()
					.then(res => {
						return res.data.data;
					})
					.catch(e => {
						alert(e);
						return false;
					}),
			]);
			this.isFetchedMainTabs = mainTags;
			return mainTags;
		} else {
			return this.isFetchedMainTabs;
		}
	};
	@action getWelcomeMsg = async () => {
		if (!this.isFetchedWelcomeMsg) {
			const welcomeMsg = await Promise.all([
				postAPI
					.getWelcomeMsg()
					.then(res => {
						return res.data['message'];
					})
					.catch(e => {
						alert(e);
						return false;
					}),
			]);
			this.isFetchedWelcomeMsg = welcomeMsg;
			return welcomeMsg;
		} else {
			return this.isFetchedWelcomeMsg;
		}
	};

	@action toggleMoreInfo = () => {
		this.moreToggle = !this.moreToggle;
	};

	@action toggleComment = () => {
		this.commentToggle = !this.commentToggle;
	};

	@action togglePostDelete = () => {
		this.deleteToggle = !this.deleteToggle;
	};
	@action setCreatePost = data => {
		this.createPost = data;
	};
	// WEB 전용 업로드 메서드
	@action addVideo = video => {
		const videoDict = {
			id: undefined,
			video: video,
			frame: [],
			init: false,
			duration: undefined,
			start: undefined,
			end: undefined,
			startPos: undefined,
			endPos: undefined,
			currentDuration: undefined,
		};
		const videos = this.addedVideoArr.concat(videoDict);
		this.addedVideoArr = videos;
		this.videoLen = this.videoLen + 1;
	};
	@action clearVideo = () => {
		this.addedVideoArr = [];
		this.videoLen = 1;
	};
	@action clearNewPost = () => {
		this.addedVideoArr = [];
		this.iosAddedVideoArr = [];
		this.editedVideoArr = [];
		this.iosEditedVideoArr = [];
		this.videoLen = 1;
		this.editedVideoLen = 1;
		this.createPost = {
			title: undefined,
			tags: undefined,
			links: [],
			linkNum: undefined,
			thumbnail_image: undefined,
			profile: undefined,
		};
	};
	@action delVideo = index => {
		this.addedVideoArr.splice(index, 1);
		this.videoLen = this.videoLen - 1;
	};

	// IOS 전용 업로드 메서드
	@action iosAddVideo = res => {
		const videoDict = {
			id: res.id,
			videoUrl: res.url,
		};
		const videos = this.iosAddedVideoArr.concat(videoDict);
		this.iosAddedVideoArr = videos;
		this.videoLen = this.videoLen + 1;
	};
	// IOS 전용 비디오 삭제 버튼 메서드
	@action iosDelVideo = index => {
		this.iosAddedVideoArr.splice(index, 1);
		this.videoLen = this.videoLen - 1;
	};

	// WEB 전용 수정 메서드
	@action addEditedVideo = (video, id, isUrl) => {
		const videoDict = {
			id: id,
			video: video,
			frame: [],
			init: false,
			duration: undefined,
			start: undefined,
			end: undefined,
			startPos: undefined,
			endPos: undefined,
			currentDuration: undefined,
			isUrl: isUrl,
			ios: false,
		};
		const videos = this.editedVideoArr.concat(videoDict);
		this.editedVideoArr = videos;
		this.editedVideoLen = this.editedVideoLen + 1;
	};
	@action delEditedVideo = index => {
		this.editedVideoArr.splice(index, 1);
		this.editedVideoLen = this.editedVideoLen - 1;
	};

	// IOS 전용 수정 메서드
	@action iosAddEditedVideo = res => {
		const videoDict = {
			id: res.id,
			video: res.url,
			frame: [],
			init: false,
			duration: undefined,
			start: undefined,
			end: undefined,
			startPos: undefined,
			endPos: undefined,
			isUrl: true,
			ios: true,
		};
		const videos = this.editedVideoArr.concat(videoDict);
		this.editedVideoArr = videos;
		this.editedVideoLen = this.editedVideoLen + 1;
	};
}
