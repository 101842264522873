import React from 'react';
import styled, { css } from 'styled-components';
import DetailMoreIcon from '../myIcons/detailMoreIcon';
import CloseIcon from '../myIcons/closeIcon';
import SearchIcon from 'components/myIcons/searchIcon';

const Layout = styled.div`
	padding-top: 28px;
	padding-bottom: 8px;
	z-index: 10;
	width: 100%;
	color: white;
	text-align: center;
`;
const Button = styled.button`
	background-color: transparent;
	border: none;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	margin-right: 4px;
	margin-left: 4px;
	padding-right: 0px;
	padding-left: 0px;
	${props =>
		props.selected &&
		css`
			color: #828ff7;
			border-bottom: 2px solid #828ff7;
		`}
`;
const Detail = styled.span`
	position: absolute;
	right: 16px;
`;
const Close = styled.span`
	position: absolute;
	left: 16px;
`;
const Dot = styled.div`
	position: absolute;
	width: 8px;
	height: 8px;
	left: 29px;
	border-radius: 50%;
	top: 15px;
	border: 1px solid #f8f8f8;
	background-color: #828ff7;
`;
const IconWrap = styled.i`
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
const Nav = styled.div`
	display: flex;
	justify-content: space-between;
`;
export default ({
	isRecommendation,
	disableRecommendationAndFollowing,
	onClick,
	newNoti,
	onClose,
}) => (
	<Layout>
		{disableRecommendationAndFollowing ? null : (
			<>
				<Nav>
					<div style={{ marginLeft: 16 }}>
						<IconWrap style={{ paddingLeft: 4, width: 24 }} key={1}>
							<svg
								style={{ verticalAlign: 'middle' }}
								onClick={() => onClick('noti')}
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="20"
								viewBox="0 0 16 20"
							>
								<path
									fill="#333"
									d="M12 9c0-2.48-1.51-4.5-4-4.5S4 6.52 4 9v6h8zm4 7v1H0v-1l2-2V9c0-3.08 1.64-5.64 4.5-6.32V2c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v.68C12.37 3.36 14 5.93 14 9v5zM6 18h4c0 1.1-.9 2-2 2s-2-.9-2-2z"
								/>
							</svg>
						</IconWrap>
						,
						<Dot
							key={2}
							style={{ display: newNoti ? 'block' : 'none', top: 30, left: 26 }}
						/>
					</div>
					<div>
						<Button
							selected={isRecommendation}
							onClick={() => onClick('recommendation')}
							role="button"
						>
							추천
						</Button>
						<Button
							selected={!isRecommendation}
							onClick={() => onClick('following')}
							role="button"
						>
							팔로잉
						</Button>
					</div>
					<SearchIcon
						onClick={() => onClick('search')}
						style={{ width: 24, marginRight: 16 }}
					/>
				</Nav>
			</>
		)}
		{onClose && (
			<Close
				onClick={e => {
					e.stopPropagation();
					onClose();
				}}
			>
				<CloseIcon style={{ fill: 'white' }} />
			</Close>
		)}
	</Layout>
);
