import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import MySwitch from 'components/mySwitch';

import history from 'utils/history';
import back_ico from 'images/back_ico_b.svg';

const NavBar = styled.div`
	display: flex;
	flex-direction: row;
	position: fixed;
	line-height: 56px;
	height: 56px;
	width: 100%;
	max-width: 1024px;
	z-index: 3;
	top: 0;
	background: rgb(248, 248, 248);
`;
const ContentWrap = styled.div`
	overflow-y: auto;
	padding: 16px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	p {
		margin-bottom: 10px;
	}
`;
const SettingBox = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 16px;
	margin-bottom: 24px;
	min-height: 104px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #ffffff;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 15px;
	font-weight: 500;
	span {
		flex: 1 1 auto;
		display: inline-block;
		width: 100%;
	}
	div {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 24px;
	}
	div:last-child {
		margin-bottom: 0px;
	}
`;

@inject('userStore')
@observer
class MySetting extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			accessToken: this.props.userStore.auth_token,
		};
	}
	componentWillMount() {
		if (!this.props.userStore.auth_token) {
			history.push('/login');
		}
	}
	logout = () => {
		const f = window.confirm('정말 로그아웃 하시겠습니까?');
		if (f) {
			localStorage.removeItem('setupTime');
			localStorage.removeItem('ms_auth_token');
			this.props.userStore.logout();
			//iosHandler.logout();
			history.push('/');
		}
	};
	render() {
		const {
			followingData,
			followerData,
			followingResults,
			followerResults,
		} = this.state;
		return (
			<Wrap
				style={{
					paddingBottom: 0,
					height: '-webkit-fill-available',
					backgroundColor: 'rgb(248,248,248)',
				}}
			>
				<IndexWrap style={{ paddingTop: 56, backgroundColor: '#f8f8f8' }}>
					<NavBar>
						<div style={{ width: '50%' }}>
							<img
								src={back_ico}
								onClick={() => {
									history.goBack();
								}}
								style={{
									marginLeft: 19,
									width: 19,
									verticalAlign: 'middle',
									cursor: 'pointer',
								}}
							/>
						</div>
					</NavBar>
					<ContentWrap>
						<p> 알림 </p>
						<SettingBox>
							<div>
								<span>푸쉬 설정 및 수신동의</span>
								<MySwitch />
							</div>
							<div>
								<span>위치기반 서비스 이용 동의</span>
								<MySwitch />
							</div>
						</SettingBox>
						<p> 고객지원 </p>
						<SettingBox>
							<div>
								<span>1:1 문의하기</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="7"
									height="12"
									viewBox="0 0 7 12"
								>
									<path
										fill="#bebebe"
										d="M.29.71a.996.996 0 0 0 0 1.41L4.17 6 .29 9.88a.996.996 0 1 0 1.41 1.41L6.29 6.7a.996.996 0 0 0 0-1.41L1.7.7C1.32.32.68.32.29.71z"
									/>
								</svg>
							</div>
							<div>
								<span>공지사항</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="7"
									height="12"
									viewBox="0 0 7 12"
								>
									<path
										fill="#bebebe"
										d="M.29.71a.996.996 0 0 0 0 1.41L4.17 6 .29 9.88a.996.996 0 1 0 1.41 1.41L6.29 6.7a.996.996 0 0 0 0-1.41L1.7.7C1.32.32.68.32.29.71z"
									/>
								</svg>
							</div>
						</SettingBox>
						<p> 이용안내 </p>
						<SettingBox>
							<div
								role="button"
								onClick={() => {
									window.location.href = 'http://lay-er.com/agree';
								}}
							>
								<span>서비스 이용 약관</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="7"
									height="12"
									viewBox="0 0 7 12"
								>
									<path
										fill="#bebebe"
										d="M.29.71a.996.996 0 0 0 0 1.41L4.17 6 .29 9.88a.996.996 0 1 0 1.41 1.41L6.29 6.7a.996.996 0 0 0 0-1.41L1.7.7C1.32.32.68.32.29.71z"
									/>
								</svg>
							</div>
							<div
								role="button"
								onClick={() => window.open('http://lay-er.com/privacy-policy')}
							>
								<span>개인정보처리방침</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="7"
									height="12"
									viewBox="0 0 7 12"
								>
									<path
										fill="#bebebe"
										d="M.29.71a.996.996 0 0 0 0 1.41L4.17 6 .29 9.88a.996.996 0 1 0 1.41 1.41L6.29 6.7a.996.996 0 0 0 0-1.41L1.7.7C1.32.32.68.32.29.71z"
									/>
								</svg>
							</div>
							<div
								role="button"
								onClick={() => window.open('http://lay-er.com')}
							>
								<span>사업자 정보</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="7"
									height="12"
									viewBox="0 0 7 12"
								>
									<path
										fill="#bebebe"
										d="M.29.71a.996.996 0 0 0 0 1.41L4.17 6 .29 9.88a.996.996 0 1 0 1.41 1.41L6.29 6.7a.996.996 0 0 0 0-1.41L1.7.7C1.32.32.68.32.29.71z"
									/>
								</svg>
							</div>
						</SettingBox>
						<p> 시스템 </p>
						<SettingBox>
							<div>
								<span>버전 정보</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="7"
									height="12"
									viewBox="0 0 7 12"
								>
									<path
										fill="#bebebe"
										d="M.29.71a.996.996 0 0 0 0 1.41L4.17 6 .29 9.88a.996.996 0 1 0 1.41 1.41L6.29 6.7a.996.996 0 0 0 0-1.41L1.7.7C1.32.32.68.32.29.71z"
									/>
								</svg>
							</div>
							<div>
								<span onClick={this.logout}>로그아웃</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="7"
									height="12"
									viewBox="0 0 7 12"
								>
									<path
										fill="#bebebe"
										d="M.29.71a.996.996 0 0 0 0 1.41L4.17 6 .29 9.88a.996.996 0 1 0 1.41 1.41L6.29 6.7a.996.996 0 0 0 0-1.41L1.7.7C1.32.32.68.32.29.71z"
									/>
								</svg>
							</div>
						</SettingBox>
					</ContentWrap>
				</IndexWrap>
			</Wrap>
		);
	}
}
export default MySetting;
