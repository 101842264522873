/* global Swiper */
import React from 'react';
import UserProfilePage from './userProfilePage';

class Home extends React.Component {
	static defaultProps = {
		swiperClassName: 'swiper-container-home-with-a-profile',
	};
	constructor(props) {
		super(props);
		const {
			location: { search },
		} = window;
		const idx = search.indexOf('?swiperIdx') > -1 ? 1 : 0;
		this.state = {
			swiper: null,
			postNow: null,
			idx,
		};
	}

	render() {
		const { match } = this.props;
		const {
			params: { profileId },
		} = match;
		return (
			<UserProfilePage
				// onpopstate는 search에서만 사용
				id={parseInt(profileId)}
				goBack={() => (window.location.href = '/')}
				onClickItem={({ index }) => {
					// 클릭시에만 넘어갈 수 있게 풀어준다.
					this.swiper.slideNext();
				}}
			/>
		);
	}
}
export default Home;
