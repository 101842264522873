import React, { Fragment } from 'react';
import 'antd-mobile/dist/antd-mobile.css';
import { Icon } from 'antd';
import { NavBar } from 'antd-mobile';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import * as userAPI from 'axios/userAPI';

import history from 'utils/history';

import { ReactComponent as SearchIco } from 'images/search_ico.svg';
import logo from 'images/navBar_logo.png';

const NavWrap = styled.div`
	z-index: 10;
	position: fixed;
	background: transparent;
	width: 100%;
	max-width: 1024px;
	// transition: 0.5s;
	${props =>
		props.toggle &&
		css`
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
		`}
`;

const LogoImg = styled.div`
  background-image:url('${logo}');
  width: 47px;
  height: 31px;
  background-size:cover;
  background-position:center;
`;

const DropDown = styled.div`
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	cursor: pointer;
	${props => props.toggle && css``}
`;
const FixedDropDown = styled.div`
	position: fixed;
	z-index: -1;
	height: 100px;
	top: -255px;
	width: 100%;
	max-width: 1024px;
	border-radius: 0 0 20px 20px;
	background-color: #ffffff;
	transition: 0.2s;
	${props =>
		props.toggle &&
		css`
			z-index: 10;
			top: 0px;
			box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
		`}
	${props =>
		props.typeChanged &&
		css`
			transition: 0s;
		`}
`;

const DropTitle = styled.div`
	width: 100%;
	height: 44px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 700;
	line-height: 44px;
	text-align: center;
	border-bottom: solid 0.5px #ebebeb;
	cursor: pointer;
`;
const DropItem = styled.div`
	width: 100%;
	height: 28px;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 500;
	line-height: 28px;
	margin-top: 16px;
	text-align: center;
	cursor: pointer;
`;
const Dot = styled.div`
	position: absolute;
	width: 8px;
	height: 8px;
	left: 29px;
	border-radius: 50%;
	top: 15px;
	border: 1px solid #f8f8f8;
	background-color: #828ff7;
`;
const MainFilter = styled.div`
	span:first-child {
		margin-right: 8px;
	}
`;
const FilterText = styled.span`
	color: #bebebe;
	font-family: 'Noto Sans CJK KR';
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
	${props =>
		props.name == props.clicked &&
		css`
			color: #333333;
			font-family: 'Noto Sans CJK KR';
			font-size: 15px;
			font-weight: 700;
		`}
`;
const IconWrap = styled.i`
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
const Divider = styled.span`
	display: inline-block;
	vertical-align: middle;
	width: 3px;
	height: 3px;
	background: #bebebe;
	border-radius: 50%;
	margin-right: 8px;
`;

@inject('userStore')
@observer
class TopNavBar extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			login: true,
			dropDownClicked: false,
			allType: true,
			typeChanged: false,
			newNoti: false,
		};
	}
	async componentDidMount() {
		this._isMounted = true;
		const token = this.props.userStore.auth_token;
		if (token) {
			const req = {
				headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
			};
			const res = await userAPI.getIsNewNoti(req).then(res => {
				return res;
			});
			if (res.data && this._isMounted) {
				this.setState({
					newNoti: res.data.is_new,
				});
			}
		}
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	togglePostType = () => {
		this.setState({
			allType: !this.state.allType,
			typeChanged: true,
		});
	};
	toggleDropDown = () => {
		if (this.props.userStore.getTokenOrToggleLogin()) {
			this.setState({
				dropDownClicked: !this.state.dropDownClicked,
				typeChanged: false,
			});
		}
	};
	_outsideClick = () => {
		if (this.state.dropDownClicked) {
			this.setState({
				dropDownClicked: false,
			});
		}
	};
	toNotify = () => {
		if (this.props.userStore.getTokenOrToggleLogin()) {
			history.push('/notification');
		}
	};
	toSearch = () => {
		history.push('/search');
	};
	toSns = url => {
		if (url) window.open(url, '_blank');
	};
	render() {
		let instagram;
		let youtube;
		if (this.props.myProfile) {
			if (this.props.myProfile.instagram)
				instagram = this.props.myProfile.instagram;
			if (this.props.myProfile.youtube) youtube = this.props.myProfile.youtube;
		}
		return (
			<NavWrap
				toggle={this.state.dropDownClicked}
				onClick={this._outsideClick}
				style={this.props.style}
			>
				<NavBar
					mode="light"
					icon={[
						<IconWrap style={{ paddingLeft: 4, width: '30px' }} key={1}>
							<svg
								style={{ verticalAlign: 'middle' }}
								onClick={this.toNotify}
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="20"
								viewBox="0 0 16 20"
							>
								<path
									fill="#333"
									d="M12 9c0-2.48-1.51-4.5-4-4.5S4 6.52 4 9v6h8zm4 7v1H0v-1l2-2V9c0-3.08 1.64-5.64 4.5-6.32V2c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v.68C12.37 3.36 14 5.93 14 9v5zM6 18h4c0 1.1-.9 2-2 2s-2-.9-2-2z"
								/>
							</svg>
						</IconWrap>,
						<Dot
							key={2}
							style={{ display: this.state.newNoti ? 'block' : 'none' }}
						/>,
					]}
					rightContent={
						this.props.myPage ? (
							<Fragment>
								<IconWrap
									style={{ paddingTop: 6 }}
									onClick={() => {
										history.push('/my-setting');
									}}
								>
									<Icon
										type="setting"
										style={{ color: 'black', fontSize: 20, cursor: 'pointer' }}
									/>
								</IconWrap>
							</Fragment>
						) : (
							[
								<IconWrap key={1} style={{ paddingRight: 5 }}>
									<SearchIco
										width="17px"
										style={{ verticalAlign: 'middle' }}
										onClick={
											this.props.myLike
												? this.props.myLikeSearch
												: this.toSearch
										}
									/>
								</IconWrap>,
							]
						)
					}
				>
					{this.props.home ? (
						//<DropDown onClick={this.toggleDropDown} toggle={this.state.dropDownClicked}> {this.state.allType ? '전체' : '팔로잉'} <Icon type="down" style={{fontSize: '11px', verticalAlign: 'baseline'}}/> </DropDown>
						<MainFilter>
							<FilterText
								name="추천"
								clicked={this.props.mainFilter}
								onClick={() => {
									if (this.props.mainFilter === '추천') {
										window.location.href = '/';
									} else {
										this.props.handleMainFilter('추천');
									}
								}}
							>
								추천
							</FilterText>
							<Divider />
							<FilterText
								name="팔로잉"
								clicked={this.props.mainFilter}
								onClick={() => {
									if (this.props.mainFilter === '팔로잉') {
										window.location.href = '/';
									} else {
										this.props.handleMainFilter('팔로잉');
									}
								}}
							>
								팔로잉
							</FilterText>
						</MainFilter>
					) : (
						<Fragment />
					)}
					{this.props.myLike ? (
						//<DropDown onClick={this.toggleDropDown} toggle={this.state.dropDownClicked}> {this.state.allType ? '전체' : '팔로잉'} <Icon type="down" style={{fontSize: '11px', verticalAlign: 'baseline'}}/> </DropDown>
						<MainFilter>
							<FilterText>좋아요</FilterText>
						</MainFilter>
					) : (
						<Fragment />
					)}
				</NavBar>
			</NavWrap>
		);
	}
}
export default TopNavBar;
