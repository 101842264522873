/* global Swiper */
import React from 'react';
import { observer, inject } from 'mobx-react';
import SpinnerCurtain from '../components/loadingSpinner/spinnerCurtain';
import ProductCategoryModal from 'components/popModal/productCategoryModal';
import PostModalHeader from 'components/PostModalHeader';
import UserProfilePage from './userProfilePage';
import Posts from './posts';
import history from 'utils/history';

@inject('userStore')
@observer
class Home extends React.Component {
	static defaultProps = {
		swiperClassName: 'swiper-container-home',
	};
	constructor(props) {
		super(props);
		const {
			location: { search },
		} = window;
		const idx = search.indexOf('?swiperIdx') > -1 ? 1 : 0;
		this.state = {
			swiper: null,
			postNow: null,
			idx,
			productCategoryModalOpen: false,
		};
	}
	componentDidMount() {
		this.swiper = new Swiper(`.${this.props.swiperClassName}`, {
			initialSlide: this.state.idx,
			on: {
				transitionEnd: () => {
					// 좌우 스크롤용
					if (this.swiper) {
						const { realIndex } = this.swiper;
						this.setState({ idx: realIndex });
						if (realIndex > 0) {
							console.log('pushState, 0');
							window.history.pushState(null, null, `?swiperIdx=${realIndex}`);
						} else {
							const {
								location: { pathname },
							} = window;
							console.log('pushState, 1');
							window.history.replaceState(null, null, pathname);
						}
					}
				},
			},
		});
		this.setState({
			swiper: this.swiper,
		});
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.location) {
			if (nextProps.location.search === '' && this.swiper.realIndex === 1) {
				this.swiper.slideTo(0);
			}
			if (
				nextProps.location.search.includes('swiperIdx') &&
				this.swiper.realIndex === 0
			) {
				this.swiper.slideTo(1);
			}
		} else if (this.swiper && this.swiper.realIndex === 1) {
			this.swiper.slideTo(0);
		}
	}
	handleClickAdd = () => {
		this.setState({
			productCategoryModalOpen: true,
		});
	};
	render() {
		const {
			match,
			swiperClassName,
			postId,
			thumbnail,
			postProps = {},
		} = this.props;
		const { swiper, idx } = this.state;
		return (
			<>
				<div className={`swiper-container ${swiperClassName}`}>
					<div className="swiper-wrapper">
						<div className="swiper-slide">
							<Posts
								thumbnail={thumbnail}
								activeRefresh
								stopVideo={idx !== 0}
								isFollowing={window.location.search.includes('following')}
								id={postId || match.params.id}
								onChange={(postNow, postIdx) => {
									const {
										location: { search },
									} = window;
									// 첫 접속이 프로필 포스트 페이지일경우 그 프로필을 기준으로 아이디를 잡아준다.
									if (!this.props.disableDefaultTransitionEvent) {
										if (postIdx === 0) {
											window.history.replaceState(null, null, `/${search}`);
										} else {
											if (postNow)
												window.history.replaceState(
													null,
													null,
													`/${postNow.id}${search}`,
												);
										}
									}
									this.setState({
										postNow,
									});
								}}
								handleClickProfileImg={() => swiper.slideTo(1)}
								onClickAdd={this.handleClickAdd}
								muted={false}
								{...postProps}
							/>
						</div>
						<div className="swiper-slide">
							<UserProfilePage
								// onpopstate는 search에서만 사용
								onpopstate={this.props.onpopstate}
								postsSwiperClass={this.props.userProfileSwiperClass}
								id={
									idx === 1 &&
									this.state.postNow &&
									this.state.postNow.profile.id
								}
								goBack={() => this.swiper.slidePrev()}
								onClickItem={({ index }) => {
									// 클릭시에만 넘어갈 수 있게 풀어준다.
									this.swiper.slideNext();
								}}
							/>
						</div>
					</div>
				</div>
				<ProductCategoryModal
					open={this.state.productCategoryModalOpen}
					handleClose={() =>
						this.setState({
							productCategoryModalOpen: false,
						})
					}
					onClick={selected => {
						if (this.props.userStore.getTokenOrToggleLogin())
							history.push(`/new-post?selected=${selected}`);
					}}
					bottom={'0px'}
				/>
			</>
		);
	}
}
export default Home;
