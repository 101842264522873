/* global AppleID */
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import GoogleLogin from 'react-google-login';
import { observer, inject } from 'mobx-react';
import {
	Wrap,
	IndexWrap,
	MainEventWrap,
} from '../components/style/custom-styled-components';

import {
	NAVER_KEY,
	KAKAO_JS_KEY,
	KAKAO_REST_KEY,
	GOOGLE_KEY,
	GOOGLE_IOS_KEY,
	isMobile,
} from 'axios/config';
import * as userAPI from 'axios/userAPI';

import * as iosHandler from 'utils/iosHandler';
import history from 'utils/history';

import login_logo from 'images/login_logo_.png';
import social_google from 'images/social_google.png';
import social_kakao from 'images/social_kakao.png';

const LogoWrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin: auto;
	height: 47vh;
`;
const LogoImg = styled.div`
    background-size:cover;
    background-position:center;
    width:153px;
    height:102px;
    margin:auto;
    margin-bottom:0px;
    background-image:url('${login_logo}');
`;
const LogoText = styled.div`
	width: 171px;
	height: 49px;
	margin: auto;
	margin-top: 18px;
	margin-bottom: 0px;
	color: #828ff7;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
`;
const SocialWrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin: auto;
	height: 53vh;
`;
const SocialBtn = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 281px;
	overflow: hidden;
	// height: 44px;
	min-height: 44px !important;
	margin: auto;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
	border-radius: 22px;
	background-color: #ffffff;
	text-align: center;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	line-height: 44px;
	margin-bottom: 16px;
	margin-top: 0px;
	border: 1px solid black;
	padding: 1px;
	:hover {
		opacity: 0.7;
	}
	cursor: pointer;
	div {
		div {
			max-height: 44px;
		}
	}
`;
const CancelBtn = styled.div`
	min-width: 282px;
	height: 44px;
	line-height: 44px;
	box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
	border-radius: 68px;
	background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	text-align: center;
	cursor: pointer;
	:hover {
		opacity: 0.7;
	}
`;
window.Kakao.init(KAKAO_JS_KEY);
@inject('userStore')
@observer
class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			accessToken: this.props.userStore.auth_token,
			loading: false,
		};
		this.login = this.login.bind(this);
	}
	async componentDidMount() {
		window.loginApp = this;
		if (this.props.userStore.auth_token) {
			const req = {
				headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
			};
			const profile = await Promise.all([
				userAPI
					.getMyUserProfile(req)
					.then(res => {
						return res.data;
					})
					.catch(e => {
						return false;
					}),
			]);
			if (profile[0].is_updated) {
				history.push('/');
			} else {
				history.push('/signup');
			}
		} else {
			this.naverLogin = new window.naver.LoginWithNaverId({
				clientId: NAVER_KEY,
				callbackUrl: 'https://lay-er.me/login',
				isPopup: false,
				loginButton: { color: 'green', type: 3, height: 60 },
			});
			this.naverLogin.init();
			window.addEventListener('load', this._naverLogin, false);
		}
		// 애플 로그인 시작 및 초기화
		AppleID.auth.init({
			clientId: 'com.lay-er.app',
			scope: 'name email',
			redirectURI: 'http://login.lay-er.me/api/loggined',
			// redirectURI: 'http://api.dev.lay-er.me/api/v1/apple/login/',
			state: 'sign in',
		});
		// 애플 로그인은 다른 로그인 방법과는 다르다.
		// accessToken과 프로필이 바로 넘어온다.
		// 이걸로 바로 로그인처리 (231 lines 쯤)
		// 유의할점은 프로필이 json 형태가 아닌 모습으로 넘어온다는 것이다.
		const { match, location } = this.props;
		if (match && match.path.includes('/apple')) {
			const dqs = decodeURIComponent(location.search).replace(/ /g, '');
			const IS_UPDATED_START_STR = ",'is_updated':";
			const IS_UPDATED_END_STR = ",'is_followed':";
			const TOKEN_START_STR = "'access_token':'";
			const TOKEN_END_STR = "','expires_in'";

			const isUpdated =
				dqs.slice(
					dqs.indexOf(IS_UPDATED_START_STR) + IS_UPDATED_START_STR.length,
					dqs.indexOf(IS_UPDATED_END_STR),
				) === 'True';
			const accessToken = dqs.slice(
				dqs.indexOf(TOKEN_START_STR) + TOKEN_START_STR.length,
				dqs.indexOf(TOKEN_END_STR),
			);
			const token = JSON.stringify(accessToken);
			localStorage.setItem('ms_auth_token', token);
			iosHandler.loginKey(token);
			this.props.userStore.setAccessToken(accessToken);

			if (!isUpdated) {
				history.push('/signup');
			} else {
				history.push('/');
			}
		}
		// 애플 로그인 종료
	}
	async login(req) {
		let user = [];
		if (req.backend == 'naver') {
			user = await Promise.all([
				userAPI
					.postConvertTokenNaver(req)
					.then(res => {
						return res.data;
					})
					.catch(() => {
						return false;
					}),
			]);
		} else {
			console.log(req);
			user = await Promise.all([
				userAPI
					.postConvertToken(req)
					.then(res => {
						return res.data;
					})
					.catch(() => {
						return false;
					}),
			]);
			console.log(user);
		}
		if (req.backend == 'naver') {
			this.naverLogin.logout();
		}
		if (user[0]) {
			//  로그인처리 !!!!
			//  애플 로그인에서 여기대로 처리한다.
			const token = JSON.stringify(user[0].data.access_token);
			localStorage.setItem('ms_auth_token', token);
			iosHandler.loginKey(token);
			this.props.userStore.setAccessToken(user[0].data.access_token);
			// 회원가입페이지 이동 (일단 무조건 이동)
			if (!user[0].profile.is_updated) {
				history.push('/signup');
			} else {
				history.push('/');
				// history.push('/signup')
			}
		} else {
			if (req.backend == 'naver') {
				history.push('/');
			}
			alert(
				'죄송합니다. 현재 로그인 서비스에 문제가 발생하였습니다. 최대한 빠르게 처리하겠습니다.',
			);
		}
	}
	_naverLogin = () => {
		this.naverLogin.getLoginStatus(status => {
			if (status) {
				this.setState({
					loading: true,
				});
				const req = {
					grant_type: 'convert_token',
					client_id: NAVER_KEY,
					backend: 'naver',
					token: this.naverLogin.accessToken.accessToken,
				};
				this.login(req);
			}
		});
	};
	kakaoLogin = token => {
		const req = {
			grant_type: 'convert_token',
			client_id: KAKAO_REST_KEY,
			backend: 'kakao',
			token: token,
		};
		this.login(req);
	};
	googleLogin = token => {
		const req = {
			grant_type: 'convert_token',
			client_id: GOOGLE_KEY,
			backend: 'google-oauth2',
			token: token,
		};
		this.login(req);
	};
	appleLogin = token => {
		const req = {
			grant_type: 'convert_token',
			client_id: 'Apple',
			backend: 'apple',
			token: token,
		};
		this.login(req);
	};
	_kakaoLogin = () => {
		if (isMobile.IOSWebView()) {
			iosHandler.kakaoLogin();
		} else {
			const login = this.login;
			window.Kakao.Auth.login({
				success: function(authObj) {
					window.Kakao.API.request({
						url: '/v2/user/me',
						success: function(res) {
							const req = {
								grant_type: 'convert_token',
								client_id: KAKAO_REST_KEY,
								backend: 'kakao',
								token: authObj.access_token,
							};
							login(req);
						},
						fail: function(error) {},
					});
				},
				fail: function(err) {
					alert(JSON.stringify(err));
				},
				throughTalk: true,
			});
		}
	};
	_googleLogin = res => {
		if (!res.error) {
			const req = {
				grant_type: 'convert_token',
				client_id: GOOGLE_KEY,
				backend: 'google-oauth2',
				token: res.accessToken,
			};
			this.login(req);
		} else {
			return null;
		}
	};
	_cancel = () => {
		history.push('/');
	};
	render() {
		return (
			<Wrap style={{ paddingBottom: 0 }}>
				{!this.props.userStore.auth_token ? (
					<IndexWrap
						style={{
							position: 'relative',
							height: '100vh',
							backgroundColor: '#f8f8f8',
						}}
					>
						<LogoWrap>
							<LogoImg />
							<LogoText>
								지금 로그인하고
								<br />
								레이어를 올려보세요!
							</LogoText>
						</LogoWrap>
						<SocialWrap>
							<SocialBtn onClick={this._kakaoLogin}>
								{' '}
								<img
									width={18}
									height={16}
									style={{ verticalAlign: 'sub' }}
									src={social_kakao}
								/>{' '}
								카카오톡으로 시작하기{' '}
							</SocialBtn>
							<div id="naverIdLogin" style={{ display: 'none' }} />
							{isMobile.IOSWebView() ? (
								<SocialBtn onClick={iosHandler.googleLogin}>
									{' '}
									<img
										width={17}
										height={16}
										style={{ verticalAlign: 'sub' }}
										src={social_google}
									/>{' '}
									구글로 시작하기{' '}
								</SocialBtn>
							) : (
								<GoogleLogin
									clientId={GOOGLE_KEY}
									render={renderProps => (
										<SocialBtn
											onClick={renderProps.onClick}
											disabled={renderProps.disabled}
										>
											<img
												width={17}
												height={16}
												style={{ verticalAlign: 'sub' }}
												src={social_google}
											/>{' '}
											구글로 시작하기
										</SocialBtn>
									)}
									onSuccess={this._googleLogin}
									onFailure={this._googleLogin}
									cookiePolicy={'single_host_origin'}
								/>
							)}
							{/* <SocialBtn>
								<div
									id="appleid-signin"
									data-color="white"
									data-border="false"
									data-type="sign in"
								/>
							</SocialBtn> */}

							<CancelBtn
								style={{ marginBottom: '10vh' }}
								onClick={this._cancel}
							>
								로그인 없이 둘러보기
							</CancelBtn>
						</SocialWrap>
					</IndexWrap>
				) : (
					<Fragment />
				)}
			</Wrap>
		);
	}
}
export default Login;
