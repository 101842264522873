/* global Swiper */
import React from 'react';
import { observer, inject } from 'mobx-react';
import { Wrap } from '../components/style/custom-styled-components';
import VideoSwiper from 'containers/videoSwiper';
import './posts.css';

import * as postAPI from 'axios/postAPI';

@inject('userStore')
@inject('postStore')
@observer
class HomeOfAnUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			next: null,
			posts: [],
		};
	}
	// componentDidMount() {
	// 	if (this.props.show) {
	// 		this.getPostList();
	// 	}
	// }
	componentWillReceiveProps(nextProps) {
		if (!this.props.show && nextProps.show) {
			this.getPostList();
		} else if (!nextProps.show && this.props.show) {
			// 닫혀지면
			// 초기화
			this.setState({
				next: null,
				posts: [],
			});
		}
	}
	getPostList = async () => {
		// 포스트를 요청하고 상태를 업데이트 한다.
		// 프로필 Id로 필터링한다.
		const { userStore, profileId } = this.props;
		const Authorization = `Bearer ${userStore.auth_token}`;
		const headers = userStore.auth_token ? { Authorization } : undefined;
		const { data } = await postAPI.getPostList({
			headers,
			profile: profileId,
			next: this.state.next,
		});
		if (data) {
			this.setState({
				posts: this.state.posts.concat(data.data),
				next: data.next,
			});
			return data.data;
		}
	};
	render() {
		return (
			<Wrap>
				<VideoSwiper
					videoIdPrefix={this.props.videoIdPrefix || 'home-ofAnUser-video'}
					swiperClassName={
						this.props.swiperClassName || 'swiper-container-home-ofAnUser'
					}
					posts={this.state.posts}
					loadMore={this.getPostList}
					hasMore={this.state.next}
					{...this.props}
				/>
			</Wrap>
		);
	}
}
export default HomeOfAnUser;
