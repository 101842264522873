/* global Swiper */
import React, { Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import MultiVideo from 'components/MultiVideo';

const SwiperWrap = styled.div`
	position: fixed;
	bottom: 0;
	top: 0;
	width: 100%;
`;
const BarSpace = styled.div`
	width: 5px;
	height: 100%;
	background-color: transparent;
`;

const ProgressBarWrap = styled.div`
	position: absolute;
	z-index: 10;
	display: flex;
	top: 6px;
	left: 6px;
	right: 6px;
	height: 3px;
	background-color: transparent;
	width: calc(100% - 12px);
`;
const ProgressBar = styled.div`
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.2);
	position: relative;
`;
const animate = keyframes`
  from {
    width: 0px
  }

  to {
    width: 100%;
  }
`;

const ProgressFilter = styled.div`
	height: 100%;
	width: 0;
	background-color: rgba(255, 255, 255, 0.6);
	position: absolute;
	top: 0px;
	left: 0px;
	animation: ${animate} 10s linear;
	${props => css`
		animation: ${animate} ${props.duration}s linear infinite;
		animation-play-state: ${props.stop ? 'paused' : 'running'};
	`}
`;

class VideoSwiper extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// 현재 슬라이드의 post id
			nowId: null,
			// 현재 슬라이드의 profile id
			slides: [],
			virtualData: {
				slides: [],
			},
			prgBarWidth: [
				{
					id: 0,
					width: '0%',
					currentDuration: 0,
				},
			],
			refreshOn: false,
			refreshStart: null,
			stopVideo: false,
			sliding: false,
		};
	}
	async componentDidMount() {
		const { swiperClassName, posts } = this.props;

		// 세로 스크롤 초기화
		this.swiper = new Swiper(`.${swiperClassName}`, {
			direction: 'vertical',
			virtual: {
				slides: this.state.slides,
				renderExternal: data => {
					this.setState({
						virtualData: data,
					});
				},
				// addSlidesBefore: 0,
				// addSlidesAfter: 0,
			},
			on: {
				slideChange: () => {
					const slide = this.state.slides[this.swiper.realIndex];
					setTimeout(() => {
						this.props.onSwipe(this.swiper.realIndex);
						if (slide) {
							if (this.props.onChange) {
								this.props.onChange(slide);
							}
							setTimeout(() => {
								this.setState({
									nowId: slide.id,
								});
							}, 200);
						}
					}, 200);
				},
				slideChangeTransitionStart: () => {
					this.setState({ sliding: true });
				},
				slideChangeTransitionEnd: () => {
					this.setState({ sliding: false });
				},
				touchMove: e => {
					// 리프레시 계산 위해 초기 터치 Y 좌표 기록
					if (
						this.props.onRefresh &&
						this.swiper.realIndex === 0 &&
						e.touches &&
						e.touches[0]
					) {
						if (!this.state.refreshStart) {
							this.setState({ refreshStart: e.touches[0].screenY });
						}
					}
				},
				touchEnd: e => {
					// 초기 터치 Y좌표와 비교하여 리프레시 여부 결정
					if (this.props.onRefresh && this.state.refreshStart) {
						const endScreenY =
							e.changedTouches &&
							e.changedTouches[0] &&
							e.changedTouches[0].screenY;
						if (endScreenY - this.state.refreshStart > 100) {
							this.props.onRefresh();
						} else {
							this.setState({ refreshStart: null });
						}
					}
				},
				// 마지막에 도달했을 땐 새로 로딩해야한다.
				reachEnd: () => {
					if (this.state.slides.length) {
						this.props.loadMore();
					}
				},
			},
		});
		this.props.onReady(this.swiper);

		// 터치 이벤트 추가
		document.body.addEventListener('touchstart', this.unMuteSideVideos);

		if (posts && posts.length) {
			this.swiper.virtual.appendSlide(posts);
		}
	}
	componentWillReceiveProps(nextProps) {
		if (this.props.posts !== nextProps.posts) {
			const postNow = nextProps.posts[this.swiper.realIndex];
			this.setState({
				nowId: postNow ? postNow.id : null,
			});
			const newSlides = nextProps.posts.filter(
				o => !this.props.posts.find(i => i.id === o.id),
			);
			this.swiper.virtual.appendSlide(newSlides);
		}
	}
	componentDidUpdate(_, prevState) {
		if (prevState.nowId !== this.state.nowId) {
			this.setState({
				stopVideo: false,
			});
			// 프로그레스바 온
			// if (video) {
			// 	this.makePrgBar({ index, duration: video.duration });
			// }
		}
	}
	componentWillUnmount() {
		document.body.removeEventListener('touchstart', this.unMuteSideVideos);
	}
	unMuteSideVideos = () => {
		// 터치 시작시 다음 영상 미리 소리 재생하기
		// 2가지 이슈 커버 완료
		// 1. 실제 슬라이딩 이뤄지지 않았을 때 멈추기
		// 2. 슬라이드 위로 이동 시 위에 있는 영상이 재생 되어야 한다.

		// 슬라이드 데이터 찾기
		const prevVideo = this.props.posts[this.swiper.realIndex - 1];
		const nextVideo = this.props.posts[this.swiper.realIndex + 1];

		// id 구하기
		const prevVideoId = prevVideo && this.props.makeVideoId(prevVideo.id);
		const nextVideoId = nextVideo && this.props.makeVideoId(nextVideo.id);

		// DOM Element 찾기
		const prevVideoEle = prevVideoId && document.getElementById(prevVideoId);
		const nextVideoEle = nextVideoId && document.getElementById(nextVideoId);

		// mute videos by parent element
		if (prevVideoEle) {
			prevVideoEle.muted = this.props.muted;
		}
		if (nextVideoEle) {
			nextVideoEle.muted = this.props.muted;
		}
	};

	onVideoPlay = ({ video, index }) => {
		// 프로그레스바 초기화
		this.setState({
			prgBarWidth: [
				{
					id: 0,
					width: '0%',
					currentDuration: 0,
				},
			],
		});
		// 프로그레스바 온
		if (video) {
			this.makePrgBar({ index, duration: video.duration });
			this.props.onPlay();
		}
	};
	getPostNow = () => this.swiper && this.props.posts[this.swiper.realIndex];

	// 영상 진행바 메인 함수
	makePrgBar = ({ duration, index }) => {
		const postNow = this.getPostNow();

		const { video } = postNow;
		let prevIdx = 0;
		while (prevIdx < video.length) {
			// 이미 작동 중에 100으로 올리는 것은 안된다.
			// 0으로 초기화 시킨 뒤 100으로 올려야 정상 작동한다.
			this.setPrgBarWidth(prevIdx, 0, '0%');
			prevIdx += 1;
		}
		// 현재는 0으로 초기화
		this.setPrgBarWidth(index, 0, '0%');

		// 현재 영상 진행 바 시작
		setTimeout(() => {
			this.setPrgBarWidth(index, duration, '100%');
			let prevIdx = 0;
			while (prevIdx < index) {
				console.log('hi');
				this.setPrgBarWidth(prevIdx, 0, '100%');
				prevIdx += 1;
			}
		}, 100);
	};
	// 영상 진행바 서브루틴
	setPrgBarWidth = (id, currentDuration, width) => {
		const { prgBarWidth } = this.state;
		let newPrgBar = prgBarWidth.slice();
		let found = newPrgBar.findIndex(o => o.id === id);
		if (found > -1) {
			newPrgBar[found] = {
				...newPrgBar[found],
				currentDuration,
				width,
			};
		} else {
			newPrgBar.push({ id, currentDuration, width });
		}
		this.setState({
			prgBarWidth: newPrgBar,
		});
	};
	render() {
		const { prgBarWidth } = this.state;
		const postNow = this.getPostNow();
		return (
			<>
				<SwiperWrap
					className={`swiper-container ${this.props.swiperClassName}`}
				>
					<div
						className="swiper-wrapper"
						onClick={e => {
							this.setState({ stopVideo: !this.state.stopVideo });
						}}
					>
						{this.state.virtualData.slides.map(post => (
							<div
								key={post.id}
								className="swiper-slide"
								style={{
									top: `${this.state.virtualData.offset}px`,
									overflow: 'hidden',
								}}
							>
								<MultiVideo
									id={this.props.makeVideoId(post.id)}
									post={post}
									// this.props.stopVideo는 home에서 내려온다.
									active={
										!this.state.stopVideo &&
										!this.props.stopVideo &&
										this.state.nowId === post.id
									}
									onPlay={this.onVideoPlay}
									onClick={e => {
										this.setState({ stopVideo: !this.state.stopVideo });
									}}
								/>
							</div>
						))}
					</div>
				</SwiperWrap>
				{/* 영상 재생 바 */}
				{!this.state.sliding && postNow && (
					<ProgressBarWrap>
						{prgBarWidth.map(({ width, currentDuration, id }, index) => (
							<Fragment key={id}>
								<ProgressBar>
									<ProgressFilter
										duration={currentDuration || 0}
										stop={this.state.stopVideo}
										style={{
											width: width || 0,
										}}
									/>
								</ProgressBar>
								{/* 재생 바 사이 틈 */}
								{index == postNow.video.length - 1 ? (
									<Fragment />
								) : (
									<BarSpace />
								)}
							</Fragment>
						))}
					</ProgressBarWrap>
				)}
			</>
		);
	}
}
VideoSwiper.defaltProps = {
	makeVideoId: id => `video-${id}`,
	swiperClassName: 'swiper-container-videoSwiper',
};
export default VideoSwiper;
