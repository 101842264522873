import { Grid, Tabs, WhiteSpace } from 'antd-mobile';
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';

import MiniPostCard from 'components/mainCrtPost/miniPostCard';
import LikableItem from 'components/likableItem';
import Modal from 'components/modal';
import Home from 'pages/home';

import * as postAPI from 'axios/postAPI';
import * as userAPI from 'axios/userAPI';

const CrtPostCardList = styled(Grid)`
	display: block;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	padding: 0px 16px;
	padding-bottom: 110px;
	${props =>
		props.mode == 'user' &&
		css`
			padding-bottom: 16px;
		`}
	div.am-flexbox-item.am-grid-item:nth-child(odd) {
		margin-right: 3%;
	}
	div.am-flexbox-item.am-grid-item:nth-child(even) {
	}
`;

@inject('userStore')
@observer
class ProfileTabs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			postData: false,
			postDataNext: null,
			postDataPage: 0,
			itemData: false,
			itemDataNext: null,
			tabIndex: 0,
			data: null,
			loading: false,
			page: 0,
			next: null,
			modalOpen: false,
			modalIndex: -1,
			modalThumbnail: null,
		};
	}
	async componentDidMount() {
		await this.loadData();
		window.onpopstate = this.closeModal;
	}
	loadData = async () => {
		console.log('hi');
		const headers = this.props.userStore.auth_token
			? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
			: false;
		const myProfile = await userAPI
			.getMyUserProfile({ headers: headers })
			.then(res => {
				return res.data;
			});
		const req = {
			profileId: myProfile.id,
			headers: headers,
		};
		const postData = await postAPI
			.getPostList({ ...req, next: this.state.postDataNext })
			.then(res => {
				return res.data;
			});
		const itemData = await userAPI.getUserLikeItemList(req).then(res => {
			return res.data;
		});

		this.setState({
			postData: (this.state.postData || []).concat(postData.data),
			postDataNext: postData.next,
			itemData: itemData.data,
			itemDataNext: itemData.next,
			itemResults: itemData.data,
		});
		return this.state.tabIndex === 0 ? postData.data : itemData.data;
	};
	onTabChange = async (_, tabIndex) => {
		this.setState({ loading: true, data: null, page: 0, next: null });
		try {
			const data = await this.loadData({ tabIndex });
			this.setState({
				tabIndex,
				data: data.data,
				page: 0,
				next: data.next,
				loading: false,
			});
		} catch (e) {
			this.setState({
				tabIndex,
				data: null,
				page: 0,
				next: null,
				loading: false,
			});
		}
	};
	async componentDidUpdate(nextProps) {
		if (this.props.profile.id !== nextProps.profile.id) {
			this.setState({
				tabIndex: 0,
				data: null,
				next: null,
			});
			this.onTabChange(null, 0);
		}
	}
	// loadMore = async () => {
	// 	if (!this.state.loading) {
	// 		this.setState({ loading: true });
	// 		const next = this.state.page + 1;
	// 		const data = await this.requestData({
	// 			page: next,
	// 		});
	// 		const prevData = this.state.data || [];
	// 		this.setState({
	// 			data: prevData.concat(
	// 				data.data.filter(o => prevData.findIndex(j => j.id === o.id) < 0),
	// 			),
	// 			page: next,
	// 			next: data.next,
	// 			loading: false,
	// 		});
	// 	}
	// };
	render() {
		const {
			postData,
			itemData,
			modalIndex,
			itemResults,
			currentIndex,
			modalOpen,
		} = this.state;
		const tabs =
			this.props.mode == 'mypage'
				? [{ title: '내 포스팅' }, { title: '내 상품' }]
				: [{ title: '포스팅' }, { title: '상품' }];
		const hasMore =
			(this.state.page === 0 || Boolean(this.state.next)) &&
			!this.state.loading;
		let clickedPost;
		console.log(postData, Boolean(this.state.postDataNext));
		if (postData) clickedPost = postData[modalIndex];
		return (
			<>
				<Tabs
					tabs={tabs}
					initialPage={0}
					swipeable={false}
					animated={false}
					useOnPan={false}
					tabBarTextStyle={{
						fontWeight: 700,
						fontFamily: 'Noto Sans CJK KR',
						cursor: 'pointer',
					}}
					tabBarActiveTextColor={'#828ff7'}
					tabBarInactiveTextColor={'#bebebe'}
					onChange={this.onTabChange}
				>
					<div>
						{this.state.tabIndex === 0 ? (
							<InfiniteScroll
								pageStart={this.state.page}
								loadMore={this.loadData}
								hasMore={Boolean(this.state.postDataNext)}
								loader={
									<div
										className="loader"
										style={{ textAlign: 'center' }}
										key={0}
									></div>
								}
								useWindow={false}
							>
								{this.state.data ? (
									<CrtPostCardList
										mode={this.props.mode}
										data={this.state.postData}
										columnNum={2}
										hasLine={false}
										activeStyle={false}
										itemStyle={{
											height: 232,
											marginTop: 12,
										}}
										renderItem={(dataItem, index) => {
											return (
												<MiniPostCard
													data={JSON.stringify(dataItem)}
													likeBtn={false}
													onClick={
														this.props.onClickItem
															? () => this.props.onClickItem({ index })
															: null
													}
												/>
											);
										}}
									/>
								) : (
									<div />
								)}
							</InfiniteScroll>
						) : null}
					</div>
					<div>
						{this.state.tabIndex === 1 ? (
							<InfiniteScroll
								pageStart={this.state.page}
								loadMore={this.loadMore}
								hasMore={Boolean(this.state.postDataNext)}
								loader={
									<div
										className="loader"
										style={{ textAlign: 'center' }}
										key={0}
									></div>
								}
								useWindow={false}
							>
								{this.state.data ? (
									<div style={{ padding: '0px 16px' }}>
										<LikableItem data={this.state.data} />
									</div>
								) : (
									<div />
								)}
							</InfiniteScroll>
						) : null}
					</div>
				</Tabs>
				<Modal
					open={modalOpen}
					onClose={this.closeModal}
					afterClose={() => this.setState({ modalOpen: null })}
				>
					{postData && clickedPost && (
						<div style={{ height: '100%', position: 'fixed', width: '100%' }}>
							<Home
								thumbnail={clickedPost.thumbnail_image}
								onpopstate={this.closeModal}
								onClose={this.closeModal}
								postId={clickedPost.id}
								swiperClassName="myLike-swiper-home"
								userProfileSwiperClass="myLike-userprofile-swiper"
								disableDefaultTransitionEvent
								postProps={{
									disableFooter: true,
									disableRecommendationAndFollowing: true,
									onClose: this.closeModal,
									posts: postData,
									id: clickedPost.id,
									loadMore: this.loadData,
								}}
							/>
						</div>
					)}
				</Modal>
			</>
		);
	}
}

export default ProfileTabs;
