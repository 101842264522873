import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import YoutubeIcon from 'components/myIcons/youtubeIcon';
import InstaIcon from 'components/myIcons/instaIcon';
import Backdrop from './backdrop';
import Wrapper from './wrapper';

const Modal = styled.div`
	max-width: 480px;
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 0px;
	right: 0px;
	margin: auto;
	bottom: -350px;
	width: 100%;
	height: auto;
	background-color: #ffffff;
	transition: 0.2s ease;
	z-index: 1002;
	${props =>
		props.active &&
		css`
			bottom: ${props.bottom ? props.bottom : '48px'};
			box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
			border-radius: 20px 20px 0 0;
		`}
	p:not(:last-child) {
		border-bottom: solid 1px #ebebeb;
	}
`;
const ModalItem = styled.p`
	width: 100%;
	padding: 18px 16px;
	text-align: center;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	margin: 0px;
	cursor: pointer;
`;
@inject('userStore')
@observer
class UserProfileModal extends Component {
	sendMsg = () => {
		this.props.userStore.toggleNotOnService();
	};
	toBlock = () => {
		this.props.userStore.toggleNotOnService();
	};
	toReport = () => {
		const reportData = {
			reason: '',
			mode: 'profile',
			id: this.props.profile.id,
		};
		this.props.userStore.setReportData(reportData);
		this.props.userStore.toggleReport();
	};
	toSns = url => {
		if (url) window.open(url, '_blank');
	};
	render() {
		const { open, handleClose } = this.props;
		return (
			<>
				<Wrapper active={open}>
					<Backdrop open={open} handleClose={handleClose} />
					<Modal active={open} bottom={this.props.bottom}>
						<div>
							{this.props.profile.youtube && (
								<ModalItem
									onClick={() => this.toSns(this.props.profile.youtube)}
								>
									{' '}
									<YoutubeIcon /> Youtube 로 넘어가기{' '}
								</ModalItem>
							)}
							{this.props.profile.instagram && (
								<ModalItem
									onClick={() => {
										this.toSns(this.props.profile.instagram);
									}}
								>
									{' '}
									<InstaIcon /> Instagram 로 넘어가기{' '}
								</ModalItem>
							)}
							<ModalItem onClick={this.sendMsg}> 메세지 보내기 </ModalItem>
							<ModalItem onClick={this.toBlock}> 차단하기 </ModalItem>
							<ModalItem onClick={this.toReport}> 신고하기 </ModalItem>
							<ModalItem onClick={handleClose}>취소</ModalItem>
						</div>
					</Modal>
				</Wrapper>
			</>
		);
	}
}

export default UserProfileModal;
